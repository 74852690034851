import { Formik } from 'formik'
import React, { ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { selectStoreId } from '../../features/app/selectors'
import appointmentsActions from '../../features/appointments/actions'
import dialogActions from '../../features/dialog/actions'
import notificationsActions from '../../features/notifications/actions'
import { matchesEmail } from '../../libs/matches'
import { convertDateForQueryString } from '../../libs/time'
import { TypePhoneNumber } from '../../model/model'
import { FhirPatient } from '../../model/patient'
import { useTeloRouter } from '../../routing/teloRouter'
import { useCreateAppointmentMutation } from '../../services/appointments'
import {
	useCreatePatientMutation,
	useSearchPatientsQuery,
} from '../../services/fhirPatients'
import { useTeloDispatch } from '../../store'
import Button from '../../styleguide/buttons/Button'
import CircularProgress from '../../styleguide/CircularProgress'
import {
	BiggerSubtitle,
	StyledForm,
} from '../../styleguide/CommonPageComponents'
import SearchIcon from '../../styleguide/icons/SearchIcon'
import Yup from '../../yup'
import { AppointmentPatientFormBody } from './AppointmentPatientFormBody'
import { PatientFoundModal } from './PatientFoundModal'
import { PatientNotFoundModal } from './PatientNotFoundModal'
import { selectStorePractice } from '../../features/practices/selectors'
import { selectStoreByStoreId } from '../../features/stores/selectors'

type InitialValues = Omit<
	FhirPatient,
	'fhirId' | 'birthDate' | 'organization' | 'practice'
> & {
	birthDate: null | Date
}

const initialValues: InitialValues = {
	name: '',
	surname: '',
	birthDate: null,
	language: '',
	email: '',
	phone: '',
	phoneType: '' as TypePhoneNumber,
	secondaryPhone: '',
	secondaryPhoneType: '' as TypePhoneNumber,
	address: '',
	postalCode: '',
	state: '',
	city: '',
	country: '',
}

const SearchPatientButton = styled(Button)`
	align-self: center;
	justify-self: start;
	margin-bottom: 1.25rem;
`

const AppointmentPatientForm = ({
	renderSubmit,
	slot,
	appType,
	providerId,
	isTelemed,
	date,
}: {
	renderSubmit: (props: any) => ReactElement
	slot: string | null
	appType: string
	providerId: string
	isTelemed: boolean
	date: string
}) => {
	const { t } = useTranslation()

	const ValidationSchema = Yup.object().shape({
		name: Yup.string().required(),
		surname: Yup.string().required(),
		birthDate: Yup.date().max(new Date()),
		language: Yup.string(),
		email: Yup.string()
			.required()
			.matches(matchesEmail(), t('forms.invalidEmail')),
		phone: Yup.string(),
		phoneType: Yup.string(),
		secondaryPhone: Yup.string(),
		secondaryPhoneType: Yup.string(),
		address: Yup.string(),
		postalCode: Yup.string(),
		state: Yup.string(),
		city: Yup.string(),
		country: Yup.string(),
	})

	const [createFhirPatient] = useCreatePatientMutation()

	const storeId = useSelector(selectStoreId)

	const store = useSelector(selectStoreByStoreId(storeId))
	const practices = useSelector(selectStorePractice(storeId))

	const organizationFhirCode = practices?.[0]?.externalIds?.find(
		id => id.source === 'FHIR',
	)?.code

	const practiceCode = store?.externalIds.find(
		id => id.source === 'PRACTICE_ID',
	)?.code

	const [query, setQuery] = useState<{
		name: string
		surname: string
		birthDate?: string
		storeId: string
	}>()

	// when the search button is pressed the query is set, and the call starts
	const matchingPatients = useSearchPatientsQuery(
		query || { name: '', surname: '', storeId: '' },
		{ skip: !query },
	)

	const [selectedPatient, setSelectedPatient] = useState<FhirPatient>()
	const [appointmentInCreation, setAppointmentInCreation] = useState(false)

	const [formLocked, setFormLocked] = useState(true)

	const { navigate, readSearchParams } = useTeloRouter()

	const dispatch = useTeloDispatch()

	const [h, m] = (slot || '').split('.').map(x => Number(x))
	const selectedSlot = { h, m }

	const [createAppointment] = useCreateAppointmentMutation()

	const createAppointmentAndReturnToWorklist = (patient: FhirPatient) => {
		dispatch(
			dialogActions.openDialog({
				type: 'loading',
				message: t('appointment.loading'),
			}),
		)
		dispatch(appointmentsActions._setForceRefresh(true))

		createAppointment({
			patientId: patient.fhirId,
			appType: appType || '',
			storeId,
			selectedSlot,
			practitionerId: providerId,
			remote: isTelemed,
			date,
		})
			.then(result => {
				if ('data' in result) {
					//TO DO: close dialog and navigate when the new appointment is actually created and available in get worklist
					const DELAY_TIME = 7500
					setTimeout(() => {
						dispatch(dialogActions.closeDialog())
						navigate(`/store/${storeId}/worklist`)
						dispatch(
							notificationsActions.addNotification({
								type: 'success',
								message: t('patient.appointmentCreationOkNotification', {
									name: patient.name,
									surname: patient.surname,
								}),
								autoClose: true,
							}),
						)
					}, DELAY_TIME)
				} else {
					dispatch(dialogActions.closeDialog())
					setAppointmentInCreation(false)
				}
			})
			.catch(() => setAppointmentInCreation(false))
	}

	const patientFound =
		!matchingPatients.isFetching &&
		!!matchingPatients.data &&
		matchingPatients.data?.length > 0

	const patientNotFound =
		!matchingPatients.isFetching &&
		(matchingPatients.data?.length === 0 || matchingPatients.isError)
	const modalNotAlreadyShown = formLocked

	return (
		<>
			<PatientFoundModal
				open={patientFound && modalNotAlreadyShown}
				patients={matchingPatients.data || []}
				onClose={() => {
					setFormLocked(false)
				}}
				selectPatient={patient => {
					createAppointmentAndReturnToWorklist(patient)
				}}
			/>
			<PatientNotFoundModal
				open={patientNotFound && modalNotAlreadyShown}
				onClose={() => {
					setFormLocked(false)
				}}
			/>
			<Formik
				initialValues={selectedPatient || initialValues}
				enableReinitialize={true}
				validationSchema={ValidationSchema}
				validateOnChange={true}
				validateOnBlur={true}
				onSubmit={values => {
					setAppointmentInCreation(true)

					const patientPromise = selectedPatient
						? Promise.resolve(selectedPatient)
						: createFhirPatient({
								...values,
								organization: organizationFhirCode!,
								practice: practiceCode!,
								birthDate: values.birthDate
									? new Date(values.birthDate).toISOString()
									: undefined,
						  })
								.then(result => {
									if ('data' in result && result.data) {
										setSelectedPatient(result.data)
										return result.data
									} else {
										setAppointmentInCreation(false)
									}
								})
								.catch(() => setAppointmentInCreation(false))

					patientPromise.then(patient => {
						if (patient) {
							createAppointmentAndReturnToWorklist(patient)
						}
					})
				}}
			>
				{({ values }) => (
					<StyledForm $enableoverflowhidden noValidate>
						<BiggerSubtitle>{t('appointment.demographicData')}</BiggerSubtitle>

						<AppointmentPatientFormBody
							SearchPatientButton={
								<SearchPatientButton
									data-testid="search-patient"
									variant="text"
									color="secondary"
									underlined
									startIcon={
										matchingPatients.isLoading ? (
											<CircularProgress size="20px" />
										) : (
											<SearchIcon />
										)
									}
									disabled={
										!values.name ||
										!values.surname ||
										matchingPatients.isLoading
									}
									onClick={() => {
										setFormLocked(true)
										setQuery({
											name: values.name.trim().replace(/\s+/g, ' '),
											surname: values.surname.trim().replace(/\s+/g, ' '),
											birthDate: values.birthDate
												? convertDateForQueryString(values.birthDate)
												: undefined,
											storeId,
										})
									}}
								>
									{t('appointment.searchPatient')}
								</SearchPatientButton>
							}
                            storeId={storeId}
							formLocked={formLocked || !!selectedPatient}
						/>

						{renderSubmit({
							disableNext:
								!values.name ||
								!values.surname ||
								!values.email ||
								!values.birthDate ||
								appointmentInCreation,
						})}
					</StyledForm>
				)}
			</Formik>
		</>
	)
}

export default AppointmentPatientForm
