import qs from 'qs'
import { api, apiRoutes } from './api'
import { DifForm, TabDifForm } from './components/DigitalIntakeForm/types'

import {
	AgeAndValue,
	CalculatedAxialLengthData,
	CalculatedSphereEqData,
} from './components/DoctorRefractionTab/graphs/interfaces'
import {
	HasMyopiaAlertResponse,
	HasSensitivityAlertResponse,
} from './components/DoctorRefractionTab/types'
import config from './config'
import { remapStatusForApp } from './decoders/remapPrStatus'
import { remapStatusForApi } from './encoders/remapPrStatus'
import { handle401Error } from './libs/auth'
import { documentContainer } from './libs/constants'
import {
	decodeRes,
	decodeResMayNotBeJson,
	fetchBlob,
	fetchJson,
	getCommonHeaders,
} from './libs/fetchJson'
import { getBlobForPdf, getBlobFromResponse } from './libs/files'
import { convertInstrumentsToDeleteToApiPayload } from './libs/stages'
import { getSystemHeaders } from './libs/system'
import {
	buildDateQueryParam,
	checkIsDate,
	formatDateStringToISOQuery,
	getDay,
	getMonth,
	getYear,
	ONE_MINUTE,
} from './libs/time'
import { buildQueryString } from './libs/url'
import {
	AccommodationAlert,
	AccommodationData,
	AccommodationParameters,
	Age,
} from './model/accommodation'
import { AlertCategory } from './model/alerts'
import { Allergy } from './model/allergy'
import {
	CreateTabAppointmentPayload,
	LockedSlot,
	PrivacyPolicyDocumentsSignedApi,
	TeloAppointment,
	TeloClinic,
	TimeSlotData,
	UpdateAppointmentPayload,
} from './model/appointment'
import { AzureLogger } from './model/azureCommunication'
import { CDS_RULE } from './model/cds'
import { Coding } from './model/coding'
import { ConnectCoreMediaContent } from './model/contentCoreMedia'
import { Country } from './model/country'
import { SystemCodeCPT } from './model/cpt'
import { SymptomsCode } from './model/symptoms'
import { DashboardApi } from './model/dashboard'
import {
	CreatePlanPayload,
	DiagnosiPlan,
	UpdatePlanPayload,
} from './model/diagnosiPlans'
import { DifProductFindRequest, DifProductJwtRequest } from './model/difProduct'
import {
	ContactLensesApi,
	ContactLensesCatalogData,
	DiagnosisCatalogData,
	DoctorNotes,
	Exam,
	ExamApi,
	ExamDocument,
	ExamDocumentWithFileSize,
	ExamLink,
	ExamMedicalReport,
	ExamMode,
	ExamOrderResultDocument,
	ExamQuery,
	ExamQueue,
	ExamStatus,
	ExportToolData,
	HealthDescriptionsCatalogData,
	ImplantableDevice,
	ImplantableDeviceError,
	Modifier,
	NewExamData,
	OrderResult,
	SaveExamConditionPayload,
	StrippedExam,
	VisionTherapyApi,
	WithExamId,
} from './model/exam'
import { ExamCondition } from './model/examCondition'
import { ExamEmergencyAccess } from './model/examEmergencyAccess'
import { BaseExamUpdate, ExamUpdateCompare } from './model/examUpdate'
import { ICD10 } from './model/icd10'
import {
	GetInstrumentDataResponseApi,
	InstrumentApiType,
	InstrumentDataApi,
	InstrumentDataResponseApi,
	InstrumentType,
	SaveInstrumentDataResponse,
} from './model/instrumentsApi'
import { LabTestCpt } from './model/labTestCpt'
import { LabTestLoinc } from './model/labTestLoinc'
import { ManualDataType, SingleManualData } from './model/manual'
import {
	CreateMedicalReportTemplate,
	MedicalReportTemplate,
	UpdateMedicalReportTemplate,
} from './model/medicalReportTemplates'
import {
	Base64Image,
	ExternalSource,
	FirstDataModel,
	Id,
	LoginResponse,
} from './model/model'
import { MyopiaCare } from './model/myopia'
import {
	CreatePanelPayload,
	MapDoctorExamTypeAverageTime,
	Panel,
	UpdatePanelPayload,
} from './model/panel'
import {
	InternalPatient,
	InternalPatientCore,
	PatientApi,
	TeloPatient,
	TeloPatientApi,
	UprisePatientAlert,
} from './model/patient'
import { Policy } from './model/policy'
import {
	CreatePracticePayload,
	Practice,
	PracticeLogoAPI,
	Surgeon,
	UpdatePracticePayload,
} from './model/practice'
import { Prescription, PrescriptionData } from './model/prescription'
import {
	CreateReferralDoctor,
	ReferralDoctor,
	ReferralReasonsCatalogData,
	ReferralSpecialtiesCatalogData,
} from './model/referralDoctors'
import { Assessment, State } from './model/renewal'
import { Report, ReportResponse } from './model/report'
import { ReferralDocumentBody } from './model/signRequests'
import { SnomedCT } from './model/snomedCT'
import { SpecialTestCpt } from './model/specialTestCpt'
import {
	CreateDocumentPayload,
	CreateStorePayload,
	MissionControlDoctorStat,
	MissionControlStatGlobalAdmin,
	MissionControlTechnicianStat,
	PrivacyPolicyDocument,
	Room,
	Stage,
	Store,
	UpdateDocumentPayload,
	UpdateStoreStaffBody,
} from './model/store'
import { Summary, SummaryContactLenses, SummaryData } from './model/summary'
import { Task, UpriseTask } from './model/task'
import { Timezone } from './model/timezone'
import { TroubleShootingData } from './model/troubleShooting'
import { CONTAINER_NAME } from './model/types'
import {
	ApiUser,
	CreateUserPayload,
	Platform,
	ProfilePhotoApi,
	SignatureApi,
	UpdateUserPayload,
	User,
} from './model/users'
import { VAScaleConversion } from './model/vaScales'
import { WarningInstrumentApi } from './model/warnings'
import { TimeoutConfig } from './model/timeoutConfig'
import { ExportState } from './features/patientExport/slice'
import {
	convertToBase64,
	getBlobFromAttachmentRef,
} from './libs/medicalReports'
import { unversionedApiUrl } from './services/common'

const routes = {
	accommodation: 'accommodation',
	alerts: 'alerts',
	allergies: 'allergies',
	appointments: 'appointments',
	assets: 'assets',
	coremedia: 'coremedia',
	difProduct: 'dif-product',
	countries: 'countries',
	cpt: 'cpt',
	dashboard: 'dashboard',
	exams: 'exams',
	icd10: 'icd10',
	internalPatients: 'internal-patients',
	log: 'log',
	login: 'login',
	logout: 'logout',
	mc: 'missionControl',
	modifiers: 'modifiers',
	myopia: 'myopia',
	office: 'office',
	panels: 'panels',
	patients: 'patients',
	pr: 'pr',
	practices: 'practices',
	prescriptions: 'prescriptions',
	reports: 'reports',
	signatures: 'signatures',
	snomedct: 'snomedct',
	states: 'states',
	stores: 'stores',
	summary: 'summary',
	timezones: 'timezones',
	trendsovertime: 'tot',
	troubleShooting: 'core-media-ts',
	users: 'users',
	vaScales: 'va-scales',
	visionTherapy: 'visionTherapy',
	opsm: 'opsm',
}

export const updatePatientApi = (patientData: PatientApi) =>
	fetchJson<PatientApi>(
		`${config.apiUrl}/${apiRoutes.patients}`,
		{
			method: 'PUT',
			body: JSON.stringify(patientData),
		},
		'errors.connection',
	)

export const getLoginTokenApi = (
	code: string,
	redirectUri: string,
	loggedInAsInStore: boolean,
	eclipsUsername?: string,
) =>
	window
		.fetch(`${config.apiUrl}/${apiRoutes.login}`, {
			credentials: 'include',
			method: 'POST',
			headers: {
				accept: 'application/json',
				'Content-type': 'application/json',
			},
			body: JSON.stringify({
				code,
				redirectUri,
				eclipsUsername,
				loggedInAsInStore,
			}),
		})
		.then(handle401Error)
		.then(res => {
			return decodeResMayNotBeJson(
				res,
				(data: any): data is LoginResponse =>
					typeof data === 'object' && 'token' in data,
			)
		})

export const doLogoutApi = () =>
	fetch(`${config.apiUrl}/${apiRoutes.logout}`, {
		headers: getCommonHeaders({ addContentType: true }),
		credentials: 'include',
		method: 'POST',
		body: JSON.stringify({}),
	}).then(_ => {
		return
	})

export const getAzureCommunicationTokenApi = () =>
	fetchJson<{
		token: string
		expiresIn: number
		communicationUserId: string
	}>(
		`${config.apiUrl}/${apiRoutes.login}/azure-communication`,
		{
			method: 'POST',
			body: JSON.stringify({}),
		},
		'errors.connection',
	)

export const getExamInstrument = (
	examId: string,
	internalPatientId: string,
	username: string,
	instrumentType: InstrumentApiType,
): Promise<InstrumentDataResponseApi> =>
	fetchJson<InstrumentDataResponseApi>(
		`${config.apiUrlV3}/${apiRoutes.exams}/${examId}/pacs/${internalPatientId}/${username}/${instrumentType}`,
		{
			method: 'GET',
		},
	)

export const getDashboard = (storeId: string, date: Date) =>
	fetchJson<DashboardApi>(
		`${config.apiUrlV2}/${apiRoutes.dashboard}/${storeId}?dateFrom=${getYear(
			date,
		)}-${getMonth(date)}-${getDay(date)}`,
		{},
		'errors.connection',
	)

export const getAppointments = (date?: Date) =>
	fetchJson<TeloAppointment[]>(
		`${config.apiUrl}/${apiRoutes.appointments}${
			date ? `?date=${getYear(date)}-${getMonth(date)}-${getDay(date)}` : ''
		}`,
		{},
		'errors.connection',
		undefined,
		ONE_MINUTE,
	)

export const getAppointmentsV2 = (date?: Date) =>
	fetchJson<TeloAppointment[]>(
		`${config.apiUrlV2}/${apiRoutes.appointments}${
			date ? `?date=${getYear(date)}-${getMonth(date)}-${getDay(date)}` : ''
		}`,
		{},
		'errors.connection',
		undefined,
		ONE_MINUTE,
	)

export const getAppointmentApi = (id: Id) =>
	fetchJson<TeloAppointment>(
		`${config.apiUrlV2}/${apiRoutes.appointments}/${id}`,
		undefined,
		'Appointment no longer available',
	)

export const getDifProductJWT = async (
	parameters: DifProductJwtRequest,
): Promise<string> => {
	const encodedParameters = new URLSearchParams(parameters).toString()
	const url = `${config.apiUrlV2}/${routes.difProduct}/jwt?${encodedParameters}`
	const response = await fetch(url, {
		method: 'GET',
		headers: getCommonHeaders({}),
	})
	return response.text()
}

export const getDifJWTAnz = async (parameters: {
	appointmentId: string
	storeCode: string
}): Promise<string> => {
	const encodedParameters = new URLSearchParams(parameters).toString()
	const url = `${config.apiUrlV2}/${routes.opsm}/jwt?${encodedParameters}`
	const response = await fetch(url, {
		method: 'GET',
		headers: getCommonHeaders({}),
	})
	return response.text()
}

export const getPatientsApi = (patientId?: Id) =>
	fetchJson<TeloPatientApi>(
		`${config.apiUrl}/${apiRoutes.patients}${patientId ? `/${patientId}` : ''}`,
		{
			method: 'GET',
		},
		'errors.connection',
	)

export const createStoreApi = (storedData: CreateStorePayload) =>
	fetchJson<Store>(
		`${config.apiUrl}/${apiRoutes.stores}`,
		{
			method: 'POST',
			body: JSON.stringify(storedData),
		},
		'errors.connection',
	)

export const getStoreApi = (id: Id) =>
	fetchJson<Store>(
		`${config.apiUrl}/${apiRoutes.stores}/${id}`,
		{},
		'errors.connection',
	)

export const getMissionControlGlobalAdmin = (storesId: Id[], date: string) =>
	fetchJson<MissionControlStatGlobalAdmin[]>(
		`${config.apiUrlV2}/${apiRoutes.mc}/globalAdmin`,
		{
			method: 'POST',
			body: JSON.stringify({ storesId, date }),
		},
		'errors.connection',
	)

export const getMissionControlTechnician = (storeId: Id, date: string) =>
	fetchJson<MissionControlTechnicianStat>(
		`${config.apiUrlV2}/${apiRoutes.mc}/technician`,
		{
			method: 'POST',
			body: JSON.stringify({ storeId, date }),
		},
		'errors.connection',
	)

export const getMissionControlDoctor = (username: string, date: string) =>
	fetchJson<MissionControlDoctorStat>(
		`${config.apiUrlV2}/${apiRoutes.mc}/doctor`,
		{
			method: 'POST',
			body: JSON.stringify({ username, date }),
		},
		'errors.connection',
	)

export const updateStoreApi = (store: Store) =>
	fetchJson<Store>(
		`${config.apiUrl}/${apiRoutes.stores}/${store._id}`,
		{
			method: 'PUT',
			body: JSON.stringify(store),
		},
		'errors.connection',
	)

export const updateStoreEnabledRefractionistApi = (
	enableRefractionist: boolean,
	storeId: string,
) =>
	fetchJson<Store>(
		`${config.apiUrl}/${apiRoutes.stores}/${storeId}`,
		{
			method: 'PUT',
			body: JSON.stringify({ enableRefractionist }),
		},
		'errors.connection',
	)

export const getAllStoresApi = (storeIds?: string) =>
	fetchJson<Store[]>(
		`${config.apiUrl}/${apiRoutes.stores}${
			storeIds ? '?storeIds=' + storeIds : ''
		}`,
		{},
		'errors.connection',
	)

export const getStagesApi = (storeId: string) =>
	fetchJson<Stage[]>(
		`${config.apiUrl}/${apiRoutes.stores}/${storeId}/stages`,
		undefined,
		'errors.connection',
	)

export type SetLockInstrumentRoomApiParams = {
	storeId: string
	stageId: Id
	roomId: Id
	examId: Id
}

export const setLockInstrumentRoomApi = ({
	storeId,
	stageId,
	roomId,
	examId,
}: SetLockInstrumentRoomApiParams) =>
	fetchJson<Stage[]>(
		`${config.apiUrl}/${apiRoutes.stores}/${storeId}/stages/${stageId}/rooms/${roomId}/lock`,
		{
			method: 'PATCH',
			body: JSON.stringify({
				examId,
			}),
		},
		'errors.connection',
	)

export const setLockDoctorRoomApi = ({
	storeId,
	stageId,
	roomId,
	examId,
}: SetLockInstrumentRoomApiParams) =>
	fetchJson<Stage[]>(
		`${config.apiUrl}/${apiRoutes.stores}/${storeId}/stages/${stageId}/rooms/${roomId}/lock-doctor-room`,
		{
			method: 'PATCH',
			body: JSON.stringify({
				examId,
			}),
		},
		'errors.connection',
	)

export const setUnlockRoomApi = ({
	storeId,
	stageId,
	roomId,
}: {
	storeId: string
	stageId: Id
	roomId: Id
}) =>
	fetchJson<Stage[]>(
		`${config.apiUrl}/${apiRoutes.stores}/${storeId}/stages/${stageId}/rooms/${roomId}/unlock`,
		{
			method: 'PATCH',
			body: JSON.stringify({}),
		},
		'errors.connection',
	)

export const addStageApi = (storeId: string, name: string) =>
	fetchJson<Stage>(
		`${config.apiUrl}/${apiRoutes.stores}/${storeId}/stages`,
		{
			method: 'POST',
			body: JSON.stringify({ name }),
		},
		'errors.connection',
	)

export const deleteStageApi = (storeId: string, stageId: Id) =>
	fetchJson<Stage[]>(
		`${config.apiUrl}/${apiRoutes.stores}/${storeId}/stages/${stageId}`,
		{
			method: 'DELETE',
			body: JSON.stringify({}),
		},
		'errors.connection',
	)

export const renameStageApi = (storeId: string, stageId: Id, name: string) =>
	fetchJson<Stage>(
		`${config.apiUrl}/${apiRoutes.stores}/${storeId}/stages/${stageId}`,
		{
			method: 'PUT',
			body: JSON.stringify({ name }),
		},
		'errors.connection',
	)

export const addRoomApi = (storeId: string, stageId: Id, name: string) =>
	fetchJson<Room>(
		`${config.apiUrl}/${apiRoutes.stores}/${storeId}/stages/${stageId}/rooms`,
		{
			method: 'POST',
			body: JSON.stringify({ name }),
		},
		'errors.connection',
	)

export const deleteRoomApi = (storeId: string, stageId: Id, roomId: Id) =>
	fetchJson<Stage[]>(
		`${config.apiUrl}/${apiRoutes.stores}/${storeId}/stages/${stageId}/rooms/${roomId}`,
		{
			method: 'DELETE',
			body: JSON.stringify({}),
		},
		'errors.connection',
	)

export const editRoomApi = (storeId: string, room: Room) =>
	fetchJson<Room>(
		`${config.apiUrl}/${apiRoutes.stores}/${storeId}/stages/${room.stageId}/rooms/${room._id}`,
		{
			method: 'PUT',
			body: JSON.stringify(room),
		},
		'errors.connection',
	)

export const removeInstrumentFromRoomApi = (
	storeId: string,
	room: Room,
	instrumentsToDelete: InstrumentType[],
) =>
	fetchJson<Room>(
		`${config.apiUrl}/${apiRoutes.stores}/${storeId}/stages/${room.stageId}/rooms/${room._id}/instruments`,
		{
			method: 'PATCH',
			body: JSON.stringify(
				convertInstrumentsToDeleteToApiPayload(instrumentsToDelete),
			),
		},
		'errors.connection',
	)

export const getExams = (params: ExamQuery) => {
	return fetchJson<(ExamApi | StrippedExam)[]>(
		`${config.apiUrl}/${apiRoutes.exams}${buildQueryString({
			...params,
		})}`,
		undefined,
		'errors.connection',
	)
}

export const getDashboardExams = (params: ExamQuery) => {
	return fetchJson<(ExamApi | StrippedExam)[]>(
		`${config.apiUrl}/dashboard-exams${buildQueryString({
			...params,
		})}`,
		undefined,
		'errors.connection',
	)
}

export const getExam = (examId: Id, notBlockingError403 = false) =>
	fetchJson<ExamApi>(
		`${config.apiUrl}/${apiRoutes.exams}/${examId}`,
		undefined,
		'errors.connection',
		false,
		undefined,
		notBlockingError403,
	)

export const updateExamApi = (
	examId: Id,
	payload: Partial<ExamApi>,
	isEndWithoutRx?: boolean,
) =>
	fetchJson<ExamApi>(
		`${config.apiUrl}/${routes.exams}/${examId}${
			isEndWithoutRx ? '?reason=endWithoutRx' : ''
		}`,
		{
			method: 'PUT',
			body: JSON.stringify(payload),
		},
		'errors.connection',
	)

export const saveAndSendReferralOrderTroughSESApi = (
	examId: Id,
	orderId: Id,
	payload: { exam: Partial<ExamApi>; referralForm: ReferralDocumentBody },
	isEndWithoutRx?: boolean,
) =>
	fetchJson<ExamApi>(
		`${config.apiUrlV3}/${
			routes.exams
		}/${examId}/sendReferralOrderToSes/${orderId}${
			isEndWithoutRx ? '?reason=endWithoutRx' : ''
		}`,
		{
			method: 'PUT',
			body: JSON.stringify(payload),
		},
		'errors.connection',
	)
export const getExamWarningsApi = (examId: Id) =>
	fetchJson<WarningInstrumentApi>(
		`${config.apiUrlV2}/${apiRoutes.exams}/${examId}/instruments-warnings`,
		{},
		'errors.connection',
	)

export const getInstrumentDataApi = (
	examId: Id,
	instrumentType: InstrumentType,
) =>
	fetchJson<GetInstrumentDataResponseApi>(
		`${config.apiUrlV2}/${apiRoutes.exams}/${examId}/instruments-data/${instrumentType}`,
		{},
		'errors.connection',
	)

export const saveInstrumentDataApi = (
	examId: Id,
	data: InstrumentDataApi,
	instrumentType: InstrumentApiType,
) => {
	return fetchJson<SaveInstrumentDataResponse>(
		`${config.apiUrlV2}/${apiRoutes.exams}/${examId}/instruments-data/${instrumentType}`,
		{
			method: 'POST',
			body: JSON.stringify(
				Array.isArray(data) ? data : { ...data, updatedAt: undefined },
			),
		},
		'errors.connection',
	)
}

export const saveManualDataApi = (
	examId: Id,
	data: SingleManualData,
	dataType: ManualDataType,
) =>
	fetchJson<ExamApi>(
		`${config.apiUrl}/${apiRoutes.exams}/${examId}/manual-data/${dataType}`,
		{
			method: 'PATCH',
			body: JSON.stringify(data),
		},
		'errors.connection',
	)

export const saveCLTrialDataApi = (examId: Id, data: ContactLensesApi[]) =>
	fetchJson<ExamApi>(
		`${config.apiUrl}/${apiRoutes.exams}/${examId}/contactLenses`,
		{
			method: 'PATCH',
			body: JSON.stringify(data),
		},
		'errors.connection',
	)

export const sendExamToQueue = (examId: Id) =>
	fetchJson<ExamQueue>(
		`${config.apiUrl}/${routes.exams}/${examId}/queue`,
		{
			method: 'PUT',
			body: JSON.stringify({}),
		},
		'errors.connection',
	)

export const getContactLensCatalogApi = (query: {
	id?: string
	include?: string
	enabled?: boolean
}) => {
	const $queryString = qs.stringify({ ...query })
	return fetchJson<ContactLensesCatalogData[]>(
		`${config.apiUrl}/contactLenses/paginated${
			$queryString.length > 0 ? `?${$queryString}` : ''
		}`,
		{
			method: 'GET',
			headers: getSystemHeaders(),
		},
		'errors.connection',
	)
}

export const getDiagnosisICDCatalogByIdApi = (id?: string) =>
	fetchJson<DiagnosisCatalogData[]>(
		`${config.apiUrl}/icd10${id ? `/${id}` : ''}`,
		{
			method: 'GET',
		},
		'errors.connection',
	)

export const getDiagnosisICDCatalogQueryApi = async (q?: string) =>
	await fetchJson<DiagnosisCatalogData[]>(
		`${config.apiUrl}/icd10${q ? `&q=${q}` : ''}`,
		{
			method: 'GET',
		},
		'errors.connection',
	)

export const getDiagnosiPlansQueryApi = async (q?: string) =>
	await fetchJson<DiagnosiPlan[]>(
		`${config.apiUrl}/diagnosi-plans${q ? `&q=${q}` : ''}`,
		{
			method: 'GET',
		},
		'errors.connection',
	)

export const getMultipleICDByCodesQueryApi = async (codes: string[]) =>
	fetchJson<DiagnosisCatalogData[]>(
		`${config.apiUrl}/icd10/codes`,
		{
			method: 'POST',
			body: JSON.stringify({ codes }),
		},
		'errors.connection',
	)

export const createDiagnosiPlansApi = (body: CreatePlanPayload) =>
	fetchJson<DiagnosiPlan>(`${config.apiUrl}/diagnosi-plans`, {
		method: 'POST',
		body: JSON.stringify(body),
	})

export const deleteDiagnosiPlansQueryApi = (id: Id) =>
	fetchJson<DiagnosiPlan>(
		`${config.apiUrl}/diagnosi-plans/${id}`,
		{
			method: 'DELETE',
		},
		'errors.connection',
	)

export const updateDiagnosiPlansApi = (planId: Id, body: UpdatePlanPayload) =>
	fetchJson<DiagnosiPlan>(`${config.apiUrl}/diagnosi-plans/${planId}`, {
		method: 'PATCH',
		body: JSON.stringify(body),
	})

export const getHealthDescriptionsCatalogApi = async (q?: string) =>
	await fetchJson<HealthDescriptionsCatalogData[]>(
		`${config.apiUrl}/health-descriptions${q ? `&q=${q}` : ''}`,
		{
			method: 'GET',
		},
		'errors.connection',
	)

export const getReferralReasonsCatalogApi = async () =>
	await fetchJson<ReferralReasonsCatalogData[]>(
		`${config.apiUrl}/referral-reasons`,
		{
			method: 'GET',
		},
		'errors.connection',
	)

export const getReferralSpecialtiesCatalogApi = async () =>
	await fetchJson<ReferralSpecialtiesCatalogData[]>(
		`${config.apiUrl}/referral-specialties`,
		{
			method: 'GET',
		},
		'errors.connection',
	)

export const createNewExamApi = (examData: NewExamData) =>
	fetchJson<ExamApi>(
		`${config.apiUrl}/${apiRoutes.exams}`,
		{
			method: 'POST',
			body: JSON.stringify(examData),
		},
		'errors.connection',
	)

export const createNewExamApiWithCopiedValues = (
	examId: Id,
	examData: NewExamData,
) =>
	fetchJson<ExamApi>(
		`${config.apiUrl}/${apiRoutes.exams}/copy/${examId}`,
		{
			method: 'POST',
			body: JSON.stringify(examData),
		},
		'errors.connection',
	)

export interface ChangeExamStatusApiParams {
	id: Id
	status: ExamStatus
	username: string
	reason?: string
	refractionist?: string
	mode?: ExamMode
	interruptedByRole?: string
	isSessionExpired?: boolean
}

export const changeExamStatusApi = ({
	id,
	status,
	username,
	reason,
	refractionist,
	mode,
	interruptedByRole,
	isSessionExpired,
}: ChangeExamStatusApiParams) =>
	fetchJson<{ exam: ExamApi; store: Store }>(
		`${config.apiUrl}/${apiRoutes.exams}/${id}/status`,
		{
			method: 'PATCH',
			body: JSON.stringify({
				status,
				username,
				reason,
				refractionist,
				mode,
				interruptedByRole,
				isSessionExpired,
			}),
		},
		'errors.connection',
	)

export type RxPatient = {
	fullname: string
	birthDate?: string
	email: string
}

export interface CreatePrescriptionPayload {
	examId: Id
	userId: Id
	appointment: TeloAppointment
	prescriptionData: PrescriptionData
	contactLensesSignature?: Base64Image
	patient: RxPatient
	signed?: boolean
	patientSignatureSource?: string
}

export interface UpdatePrescriptionPayload extends CreatePrescriptionPayload {
	prescriptionId: Id
	contactLensesSignature?: Base64Image
	signature: boolean
	send: boolean
	date: string
	blockPdfUpdating?: boolean
}

export interface UpdatePrescriptionPayload extends CreatePrescriptionPayload {
	prescriptionId: Id
}

const encodePrescriptionDataForTransmission = (data: PrescriptionData) => ({
	...data,
	Legal: {
		...data.Legal,
		ExpirationDateGlassesLensRx:
			data.Legal.ExpirationDateGlassesLensRx || undefined,
		ExpirationDateContactLensRx:
			data.Legal.ExpirationDateContactLensRx || undefined,
	},
})

export const createPrescriptionApi = ({
	examId,
	userId,
	appointment,
	prescriptionData,
	contactLensesSignature,
	patient,
	signed,
	patientSignatureSource,
}: CreatePrescriptionPayload) =>
	fetchJson<Prescription>(
		`${config.apiUrl}/${apiRoutes.prescriptions}`,
		{
			method: 'POST',
			body: JSON.stringify({
				examId: examId,
				userId: userId,
				fullname: patient.fullname,
				birthDate: patient.birthDate,
				email: patient.email,
				phone: appointment.patient.phoneNumber || '',
				signed,
				data: encodePrescriptionDataForTransmission(prescriptionData),
				contactLensesSignature,
				patientSignatureSource,
				language: navigator.language.split('-')[0].toLowerCase(),
			}),
		},
		'errors.connection',
	)

export const updatePrescriptionApi = ({
	examId,
	userId,
	appointment,
	prescriptionData,
	contactLensesSignature,
	prescriptionId,
	signature,
	send,
	patient,
	patientSignatureSource,
	date,
	blockPdfUpdating,
}: UpdatePrescriptionPayload) => {
	return fetchJson<Prescription>(
		`${config.apiUrl}/${apiRoutes.prescriptions}/${prescriptionId}`,
		{
			method: 'PUT',
			body: JSON.stringify({
				prescription: {
					examId: examId,
					userId: userId,
					fullname: patient.fullname,
					birthDate: patient.birthDate,
					email: patient.email,
					phone: appointment.patient.phoneNumber || '',
					data: encodePrescriptionDataForTransmission(prescriptionData),
					contactLensesSignature,
					patientSignatureSource,
				},
				signature,
				send,
				language: navigator.language.split('-')[0].toLowerCase(),
				blockPdfUpdating,
				date,
			}),
		},
		'errors.connection',
	)
}

export const cancelPatientSignatureApi = (prescriptionId: Id) => {
	return fetchJson<Prescription>(
		`${config.apiUrl}/${apiRoutes.prescriptions}/${prescriptionId}/cancelPatientSignature`,
		{
			method: 'PUT',
			body: JSON.stringify({}),
		},
		'errors.connection',
	)
}

export const getPrescriptionApi = (examId: Id) =>
	fetchJson<Prescription | null>(
		`${config.apiUrl}/${apiRoutes.exams}/${examId}/prescription`,
		{
			method: 'GET',
		},
		'errors.connection',
	)

export const getUserByUsernameApi = (username: string) =>
	fetchJson<ApiUser>(
		`${config.apiUrl}/${apiRoutes.users}/find/${encodeURIComponent(username)}`,
		undefined,
		'errors.connection',
	)

export const getUserByUsernameAdminApi = (username: string) =>
	fetchJson<ApiUser[]>(
		`${config.apiUrl}/${apiRoutes.users}?username=${encodeURIComponent(
			username,
		)}`,
		{},
		'errors.connection',
	)

export const getAllUsers = () =>
	fetchJson<ApiUser[]>(
		`${config.apiUrl}/${apiRoutes.users}`,
		{},
		'errors.connection',
	)

export const getStaffUsers = (storeId: string) =>
	fetchJson<ApiUser[]>(
		`${config.apiUrl}/${apiRoutes.users}?storeId=${storeId}`,
		{},
		'errors.connection',
	)

export const getPracticeUsersApi = (practiceId: string) =>
	fetchJson<ApiUser[]>(
		`${config.apiUrl}/${apiRoutes.practices}/${practiceId}/users`,
		{},
		'errors.connection',
	)

export const updateUserApi = (user: Partial<UpdateUserPayload>) =>
	fetchJson<ApiUser>(
		`${config.apiUrl}/${apiRoutes.users}/${user._id}`,
		{
			method: 'PUT',
			body: JSON.stringify(user),
		},
		'errors.connection',
	)

export const updateUserInStoreFlagApi = (
	user: Pick<User, '_id' | 'loggedInAsInStore'>,
) =>
	fetchJson<ApiUser>(
		`${config.apiUrl}/${apiRoutes.users}/${user._id}/inStore`,
		{
			method: 'PUT',
			body: JSON.stringify({ loggedInAsInStore: user.loggedInAsInStore }),
		},
		'errors.connection',
	)

export const updateUserIcdsApi = (user: Partial<UpdateUserPayload>) =>
	fetchJson<ApiUser>(
		`${config.apiUrl}/${apiRoutes.users}/${user._id}/icds`,
		{
			method: 'PUT',
			body: JSON.stringify({ favoritesIcdCodes: user.favoritesIcdCodes }),
		},
		'errors.connection',
	)

export const deleteUsersApi = (usersId: string[]) =>
	fetchJson<number>(
		`${config.apiUrl}/${apiRoutes.users}`,
		{
			method: 'PUT',
			body: JSON.stringify({ usersId }),
		},
		'errors.connection',
	)

export const createUserConditionApi = (
	userId: Id,
	body: ExamCondition,
	id?: string,
) => {
	if (id !== undefined) {
		return fetchJson<ApiUser>(
			`${config.apiUrl}/${apiRoutes.users}/${userId}/conditions/${id}`,
			{
				method: 'PUT',
				body: JSON.stringify(body),
			},
			'errors.connection',
		)
	}

	return fetchJson<ApiUser>(
		`${config.apiUrl}/${apiRoutes.users}/${userId}/conditions`,
		{
			method: 'POST',
			body: JSON.stringify(body),
		},
		'errors.connection',
	)
}

export const removeUserConditionApi = (userId: Id, body: ExamCondition[]) => {
	return fetchJson<ApiUser>(
		`${config.apiUrl}/${apiRoutes.users}/${userId}/conditions`,
		{
			method: 'PUT',
			body: JSON.stringify(body),
		},
		'errors.connection',
	)
}

export const updatePathUserConditionApi = (
	userId: Id,
	id: string,
	path: string,
	value: boolean,
) => {
	return fetchJson<ApiUser>(
		`${config.apiUrl}/${apiRoutes.users}/${userId}/conditions/${id}`,
		{
			method: 'PATCH',
			body: JSON.stringify({
				path,
				value,
			}),
		},
	)
}

export const setDisabledLEConditionsApi = (
	userId: Id,
	conditions: string[],
) => {
	return fetchJson<ApiUser>(
		`${config.apiUrl}/${apiRoutes.users}/${userId}/disabled-le-conditions`,
		{
			method: 'PATCH',
			body: JSON.stringify({ conditions }),
		},
	)
}

export const setDefaultUserConditionApi = (userId: Id, id: string) => {
	return fetchJson<ApiUser>(
		`${config.apiUrl}/${apiRoutes.users}/${userId}/conditions/${id}/default`,
		{
			method: 'PATCH',
			body: JSON.stringify({}),
		},
	)
}

export const deleteStoreApi = (id: Id) =>
	fetchJson<number>(
		`${config.apiUrl}/${apiRoutes.stores}/${id}`,
		{
			method: 'DELETE',
			body: JSON.stringify({}),
		},
		'errors.connection',
	)

export const createUserApi = (user: CreateUserPayload) =>
	fetchJson<ApiUser>(
		`${config.apiUrl}/${apiRoutes.users}`,
		{
			method: 'POST',
			body: JSON.stringify(user),
		},
		'errors.connection',
	)

export const setUserAvailabilityApi = (userId: Id, available: boolean) =>
	fetchJson<ApiUser>(
		`${config.apiUrl}/${apiRoutes.users}/${userId}/availability`,
		{
			method: 'PATCH',
			body: JSON.stringify({ available }),
		},
		'errors.connection',
	)

export const getAssetSrc = (path?: string, data?: string): string => {
	if (path) {
		// for testing purposes
		if (path.includes('placehold.co')) {
			return path
		}
		const segments = path.split('/')
		const name = segments[segments.length - 1]

		return `${config.apiUrl}/${apiRoutes.assets}/${name}`
	} else {
		return `data:image/png;base64,${data}`
	}
}

export const getAssessments = async () => {
	const response = await fetchJson<{ data: Assessment[] }>(
		`${config.apiUrl}/${apiRoutes.pr}/review/assessments`,
		undefined,
		'errors.connection',
	)
	return response && response.data.map(remapStatusForApp)
}

export const submitPrescription = async (id: string | undefined, data: any) => {
	const res = await window.fetch(
		`${config.apiUrl}/${apiRoutes.pr}/review/assessments/${id}`,
		{
			method: 'PUT',
			headers: getCommonHeaders({ addContentType: true }),
			body: JSON.stringify(remapStatusForApi(data)),
		},
	)

	return await decodeRes(res)
}

export const getAssessment = async (id: Id): Promise<Assessment> => {
	const response = await fetchJson<{ data: Assessment }>(
		`${config.apiUrl}/${apiRoutes.pr}/review/assessments/${id}`,
		{},
		'errors.connection',
	)

	return response && remapStatusForApp(response.data)
}

export const editPrescription = async (id: string | undefined, data: any) =>
	fetchJson<any>(
		`${config.apiUrl}/${apiRoutes.pr}/review/assessments/edit/${id}`,
		{
			method: 'PUT',
			body: JSON.stringify(data),
		},
		'errors.connection',
	)

export const getPrescriptionImage = async (id: string) => {
	// TODO: testing
	const response = await window.fetch(
		`${config.apiUrl}/${apiRoutes.pr}/media/image/${id}`,
		{
			headers: getCommonHeaders({}),
		},
	)

	let blob

	if (id.endsWith('.pdf')) {
		blob = new Blob([await response.blob()], { type: 'application/pdf' })
	} else {
		blob = await response.blob()
	}

	return URL.createObjectURL(blob)
}

export const getPrescriptionAudio = async (id: string) => {
	// TODO: testing
	const response = await window.fetch(
		`${config.apiUrl}/${apiRoutes.pr}/media/audio/${id}`,
		{
			headers: getCommonHeaders({}),
		},
	)

	return window.URL.createObjectURL(await response.blob())
}

export const getStates = () => {
	return fetchJson<State[]>(
		`${config.apiUrl}/${apiRoutes.pr}/states?all=true`,
		{},
		'errors.connection',
	)
}

export const setPrivacyPolicyDocumentsSignedApi = async (
	privacyPolicyDocumentsSignedPayload: PrivacyPolicyDocumentsSignedApi,
) => {
	const { examId, privacyPolicyDocumentsSigned } =
		privacyPolicyDocumentsSignedPayload

	return fetchJson<ExamApi>(
		`${config.apiUrl}/${apiRoutes.exams}/${examId}/documents`,
		{
			method: 'POST',
			body: JSON.stringify(privacyPolicyDocumentsSigned),
		},
		'errors.connection',
	)
}

export const getPrivacyPolicyPdfApi = async (
	storeId: string,
	fileRef: string,
) => {
	const response = await window.fetch(
		`${config.apiUrl}/${apiRoutes.stores}/${storeId}/documents/file/${fileRef}`,
		{
			headers: {
				...getCommonHeaders({}),
			},
		},
	)
	return window.URL.createObjectURL(await response.blob())
}

export const addStoreDocumentApi = (
	storeId: string,
	document: CreateDocumentPayload,
) =>
	fetchJson<Store>(
		`${config.apiUrl}/${apiRoutes.stores}/${storeId}/documents`,
		{
			method: 'POST',
			body: JSON.stringify(document),
		},
		'errors.connection',
	)

export const updateStoreDocumentApi = (
	storeId: string,
	documentId: string,
	documentData: UpdateDocumentPayload,
) =>
	fetchJson<Store>(
		`${config.apiUrl}/${apiRoutes.stores}/${storeId}/documents/${documentId}`,
		{
			method: 'PUT',
			body: JSON.stringify(documentData),
		},
		'errors.connection',
	)

export const generatePdfStoreDocumentApi = (
	storeId: string,
	documentId: string,
	body: PrivacyPolicyDocument,
) =>
	fetchJson<Store>(
		`${config.apiUrl}/${apiRoutes.stores}/${storeId}/ppd/documents/${documentId}`,
		{
			method: 'PUT',
			body: JSON.stringify(body),
		},
		'errors.connection',
	)

export const enableStoreDocumentApi = (
	storeId: string,
	documentId: string,
	enabled: boolean,
) =>
	fetchJson<Store>(
		`${config.apiUrl}/${apiRoutes.stores}/${storeId}/documents/${documentId}`,
		{
			method: 'PATCH',
			body: JSON.stringify({ enabled }),
		},
		'errors.connection',
	)

export const uploadExamAttachmentApi = ({
	examId,
	documentType,
	fileContent,
	fileSize,
	fileName,
	uploadedBy,
}: {
	examId: string
	documentType: string
	fileContent: string
	fileName: string
	fileSize?: number
	uploadedBy: string
}) => {
	return fetchJson<any>(
		`${config.apiUrlV2}/${apiRoutes.exams}/${examId}/documents`,
		{
			method: 'POST',
			body: JSON.stringify({
				documentType,
				fileContent,
				fileName,
				fileSize: (fileSize ?? 0).toString(),
				uploadedBy,
			}),
		},
		'errors.connection',
	)
}

export const deleteExamAttachmentApi = (
	examId: string,
	documentRef: string,
) => {
	return fetchJson<any>(
		`${config.apiUrlV2}/${apiRoutes.exams}/${examId}/documents/${documentRef}/delete`,
		{
			method: 'DELETE',
		},
		'errors.connection',
	)
}

export const saveExamLinkApi = ({
	examId,
	url,
	description,
	linkTextOptional,
	uploadedBy,
}: {
	examId: string
	url: string
	description: string
	linkTextOptional: string
	uploadedBy: string
}) => {
	return fetchJson<any>(
		`${config.apiUrlV2}/${apiRoutes.exams}/${examId}/links`,
		{
			method: 'PATCH',
			body: JSON.stringify({
				url,
				description,
				linkTextOptional,
				uploadedBy,
			}),
		},
		'errors.connection',
	)
}

export const deleteExamLinkApi = (examId: string, id: string) => {
	return fetchJson<any>(
		`${config.apiUrlV2}/${apiRoutes.exams}/${examId}/links/${id}/delete`,
		{
			method: 'DELETE',
		},
		'errors.connection',
	)
}

export const updateAttachmentApi = (
	examId: string,
	attachments: ExamDocument[],
) => {
	return fetchJson<any>(
		`${config.apiUrlV2}/${apiRoutes.exams}/${examId}/documents`,
		{
			method: 'PATCH',
			body: JSON.stringify(attachments),
		},
		'errors.connection',
	)
}

export const updateLinkApi = (examId: string, links: ExamLink[]) => {
	return fetchJson<any>(
		`${config.apiUrlV2}/${apiRoutes.exams}/${examId}/links/update`,
		{
			method: 'PATCH',
			body: JSON.stringify(links),
		},
		'errors.connection',
	)
}

export const downloadExamAttachmentApi = (
	examId: string,
	documents: ExamDocument[],
) => {
	return fetchBlob(
		`${config.apiUrlV2}/${
			apiRoutes.exams
		}/${examId}/documents/download?t=${Date.now()}`,
		{
			method: 'POST',
			body: JSON.stringify({ documents: documents }),
		},
		'errors.connection',
	)
}

export const getInternalPatientsByExternalIdApi = (externalId: string) =>
	fetchJson<InternalPatient[]>(
		`${config.apiUrl}/${apiRoutes.internalPatients}?externalId=${externalId}`,
		{},
		'errors.connection',
	)

export const getInternalPatientsApi = (patientId?: Id) =>
	fetchJson<InternalPatient[]>(
		`${config.apiUrl}/${apiRoutes.internalPatients}${
			patientId ? `/${patientId}` : ''
		}`,
		{
			method: 'GET',
		},
		'errors.connection',
	)

interface CreateNewInternalPatientPayload extends InternalPatientCore {
	externalIds: ExternalSource[]
}

export const getMatchPatientByExternalIdApi = (
	externalId: string,
	source: string,
	patientData?: CreateNewInternalPatientPayload,
) =>
	fetchJson<InternalPatient[]>(
		`${config.apiUrl}/${apiRoutes.internalPatients}/matchPatientByExternalId`,
		{
			method: 'POST',
			body: JSON.stringify({
				externalId: externalId,
				source,
				patientData: patientData,
			}),
		},
		'errors.connection',
	)

export const searchForCandidatePatientsApi = ({
	name,
	surname,
	birthDate,
	email,
}: InternalPatientCore) =>
	fetchJson<InternalPatient[]>(
		`${config.apiUrl}/${apiRoutes.internalPatients}?name=${name}&surname=${surname}&birthDate=${birthDate}&email=${email}`,
		{},
		'errors.connection',
	)

export const createInternalPatientApi = (
	data: CreateNewInternalPatientPayload,
) =>
	fetchJson<InternalPatient>(
		`${config.apiUrl}/${apiRoutes.internalPatients}`,
		{
			method: 'POST',
			body: JSON.stringify(data),
		},
		'errors.connection',
	)

export const updateInternalPatientApi = (data: InternalPatient) =>
	fetchJson<InternalPatient>(
		`${config.apiUrl}/${apiRoutes.internalPatients}/${data._id}`,
		{
			method: 'PUT',
			body: JSON.stringify(data),
		},
		'errors.connection',
	)

export const addExternalPatientToInternalPatientApi = (
	internalPatientId: Id,
	externalPatientId: Id,
	source: string,
) =>
	fetchJson<InternalPatient>(
		`${config.apiUrl}/${apiRoutes.internalPatients}/${internalPatientId}`,
		{
			method: 'PATCH',
			body: JSON.stringify({
				externalId: externalPatientId,
				source,
			}),
		},
		'errors.connection',
	)

export const getExamDocumentsFromReferenceApi = async (
	docReference: string,
	container: CONTAINER_NAME = documentContainer.ppd,
): Promise<Blob> => {
	const url = api.getDocumentsFromReference(docReference, container)

	const headers = getCommonHeaders({})

	const response = await window.fetch(url, {
		headers,
	})
	return await getBlobFromResponse(docReference, response)
}

export const getStorePPDDocumentsFromReferenceApi = async (
	docReference: string,
): Promise<Blob> => {
	const res = await fetch(
		`${config.apiUrl}/${apiRoutes.stores}/documents/ppd/${docReference}`,
		{
			headers: {
				...getCommonHeaders({}),
				'Content-Type': 'application/pdf',
			},
		},
	)

	return await getBlobForPdf(res)
}

export const getCountriesApi = () => {
	return fetchJson<Country[]>(
		`${config.apiUrl}/${apiRoutes.countries}`,
		{},
		'errors.connection',
	)
}

export const getStatesApi = () => {
	return fetchJson<State[]>(
		`${config.apiUrl}/${apiRoutes.states}`,
		{},
		'errors.connection',
	)
}

export const getTimezonesApi = () =>
	fetchJson<Timezone[]>(
		`${config.apiUrl}/${apiRoutes.timezones}`,
		{},
		'errors.connection',
	)

export const getSignatureApi = (username: string) =>
	fetchJson<SignatureApi>(
		`${config.apiUrl}/${apiRoutes.signatures}/${username}`,
		{},
		'errors.connection',
	)

export const getSignatureSelfApi = () =>
	fetchJson<SignatureApi>(
		`${config.apiUrl}/${apiRoutes.signatures}/self`,
		{},
		'errors.connection',
	)

export const saveSignatureApi = (username: string, signatureBase64: string) =>
	fetchJson<ApiUser>(
		`${config.apiUrl}/${apiRoutes.signatures}`,
		{
			method: 'PUT',
			body: JSON.stringify({ username, signatureBase64 }),
		},
		'errors.connection',
	)

export const deleteSignatureApi = (username: string) =>
	fetchJson<ApiUser>(
		`${config.apiUrl}/${apiRoutes.signatures}/${username}`,
		{
			method: 'DELETE',
		},
		'errors.connection',
	)

export const getProfilePhotoApi = (username: string) =>
	fetchJson<ProfilePhotoApi>(
		`${config.apiUrl}/${apiRoutes.users}/${username}/profile-photo`,
		{},
		'errors.connection',
	)

export const saveProfilePhotoApi = (username: string, photo: string) =>
	fetchJson<ProfilePhotoApi>(
		`${config.apiUrl}/${apiRoutes.users}/${username}/profile-photo`,
		{
			method: 'POST',
			body: JSON.stringify({ photo }),
		},
		'errors.connection',
	)

export const deleteProfilePhotoApi = (username: string) =>
	fetchJson<number>(
		`${config.apiUrl}/${apiRoutes.users}/${username}/profile-photo`,
		{
			method: 'DELETE',
		},
		'errors.connection',
	)

export const keepAliveUserApi = (username: string) =>
	fetch(
		`${config.apiUrl}/${routes.users}/keep-alive/${username}/teleoptometry`,
		{
			headers: getCommonHeaders({ addContentType: true }),
			method: 'POST',
			body: JSON.stringify({}),
		},
	)
		.then(handle401Error)
		.then(res => {
			if (res.ok) {
				return true
			} else {
				return res.json()
			}
		})
		.catch(err => {
			// this is intended for catching errors during res.json()
			console.error(err)
		})

export const getUserOnlineStatusApi = (username: string, platform: Platform) =>
	fetch(`${config.apiUrl}/${routes.users}/${username}/online/${platform}`, {
		headers: getCommonHeaders({}),
	}).then(response => (response.ok ? true : false))

export const getDoctorHistoryPatient = (doctor: string, term: string) =>
	fetchJson<(ExamApi | StrippedExam)[]>(
		`${config.apiUrlV2}/${apiRoutes.exams}/search?searchTerm=${term}`,
		{
			method: 'GET',
		},
		'errors.connection',
	)

export const updateEyeHealthDocApi = (examId: Id) =>
	fetchJson<ExamApi>(
		`${config.apiUrl}/${apiRoutes.exams}/${examId}/eye-health-document`,
		{
			method: 'PUT',
			body: JSON.stringify({}),
		},
		'errors.connection',
	)

export const retrieveEHRExport = (examId: Id) =>
	fetchBlob(
		`${config.apiUrlV2}/${apiRoutes.exams}/${examId}/export/instruments-data`,
		{
			method: 'GET',
		},
		'errors.connection',
	)

export const searchExamsApi = (searchTerm: string) => {
	const qs = checkIsDate(searchTerm)
		? buildDateQueryParam('searchTerm', searchTerm, undefined, true)
		: `?searchTerm=${searchTerm}`

	return fetchJson<(ExamApi | StrippedExam)[]>(
		`${config.apiUrlV2}/${apiRoutes.exams}/search${qs}`,
		{},
		'errors.connection',
	)
}

export const searchPatientsApi = (query: string, storeId?: string) => {
	const searchTerm = checkIsDate(query)
		? formatDateStringToISOQuery(query)
		: query

	const searchParams = new URLSearchParams({ searchTerm })

	if (storeId) {
		searchParams.append('storeId', storeId)
	}

	const url = `${config.apiUrl}/${
		apiRoutes.internalPatients
	}/search?${searchParams.toString()}`

	return fetchJson<InternalPatient[]>(url, {}, 'errors.connection')
}

export const getPanelsApi = (query?: {
	doctorUsernames?: string[]
	id?: string
}) => {
	const body = {
		doctorUsernames:
			query && query.doctorUsernames ? query.doctorUsernames.join(',') : '',
		id: query && query.id ? query.id : '',
	}

	const promise = fetchJson<Panel[]>(
		`${config.apiUrl}/${apiRoutes.panels}/list`,
		{
			method: 'POST',
			body: JSON.stringify(body),
		},
		'errors.gettingPanels',
	)
	return promise
}

export const getDoctorPanelsApi = () =>
	fetchJson<Panel[]>(
		`${config.apiUrl}/${apiRoutes.panels}/doctor`,
		{
			method: 'GET',
		},
		'errors.gettingPanels',
	)

export const getPanelDoctorsTimingApi = (panelId: string) =>
	fetchJson<MapDoctorExamTypeAverageTime>(
		`${config.apiUrl}/${apiRoutes.panels}/${panelId}/doctors-timing`,
		{
			method: 'GET',
		},
		'errors.gettingPanels',
	)

export const updatePanelApi = (panel: UpdatePanelPayload) =>
	fetchJson<Panel>(`${config.apiUrl}/${apiRoutes.panels}/${panel._id}`, {
		method: 'PUT',
		body: JSON.stringify(panel),
	})

export const createPanelApi = (panel: CreatePanelPayload) =>
	fetchJson<Panel>(`${config.apiUrl}/${apiRoutes.panels}`, {
		method: 'POST',
		body: JSON.stringify(panel),
	})

export const deletePanelApi = (panelId: Id) =>
	fetchJson<Panel>(
		`${config.apiUrl}/${apiRoutes.panels}/${panelId}`,
		{
			method: 'DELETE',
			body: JSON.stringify({}),
		},
		'errors.deletingPanel',
	)

export const setDoctorInPanelActiveApi = (panelId: Id, active: boolean) =>
	fetchJson<Panel>(`${config.apiUrl}/${apiRoutes.panels}/${panelId}`, {
		method: 'PATCH',
		body: JSON.stringify({ active }),
	})

export const addDoctorInPanelApi = (panelId: Id, username: string) =>
	fetchJson<Panel>(
		`${config.apiUrl}/${apiRoutes.panels}/${panelId}/add-doctor/${username}`,
		{
			method: 'PATCH',
			body: JSON.stringify({}),
		},
	)

export const removeDoctorInPanelApi = (panelId: Id, username: string) =>
	fetchJson<Panel>(
		`${config.apiUrl}/${apiRoutes.panels}/${panelId}/remove-doctor/${username}`,
		{
			method: 'PATCH',
			body: JSON.stringify({}),
		},
	)

export const createTabPatientApi = (patient: TeloPatient) =>
	fetchJson<TeloPatient>(`${config.apiUrl}/${apiRoutes.patients}`, {
		method: 'POST',
		body: JSON.stringify(patient),
	})

export const getPracticesApi = (query?: { id?: string; storeId?: string }) => {
	const $queryString = qs.stringify({ ...query })
	return fetchJson<Practice[]>(
		`${config.apiUrl}/${apiRoutes.practices}${
			$queryString.length > 0 ? `?${$queryString}` : ''
		}`,
		{
			method: 'GET',
		},
		'errors.practices.get',
	)
}

export const createPracticeApi = (practice: CreatePracticePayload) =>
	fetchJson<Practice>(
		`${config.apiUrl}/${apiRoutes.practices}`,
		{
			method: 'POST',
			body: JSON.stringify(practice),
		},
		'errors.practices.create',
	)

export const deletePracticeApi = (practiceId: Id) =>
	fetchJson<Practice>(
		`${config.apiUrl}/${apiRoutes.practices}/${practiceId}`,
		{
			method: 'DELETE',
			body: JSON.stringify({}),
		},
		'errors.practices.delete',
	)

export const updatePracticeApi = (practice: UpdatePracticePayload) =>
	fetchJson<Practice>(
		`${config.apiUrl}/${apiRoutes.practices}/${practice._id}`,
		{
			method: 'PUT',
			body: JSON.stringify(practice),
		},
		'errors.practices.update',
	)

export const addStoreToPracticeApi = ({
	practiceId,
	storeId,
}: {
	practiceId: Id
	storeId: Id
}) =>
	fetchJson<Practice>(
		`${config.apiUrl}/${apiRoutes.practices}/${practiceId}/add-store/${storeId}`,
		{
			method: 'PATCH',
			body: JSON.stringify({}),
		},
		'errors.practices.addStore',
	)

export const removeStoreFromPracticeApi = ({
	practiceId,
	storeId,
}: {
	practiceId: Id
	storeId: Id
}) =>
	fetchJson<Practice>(
		`${config.apiUrl}/${apiRoutes.practices}/${practiceId}/remove-store/${storeId}`,
		{
			method: 'PATCH',
			body: JSON.stringify({}),
		},
		'errors.practices.removeStore',
	)

export const getPracticeByStoreApi = (storeId: Id) =>
	fetchJson<Practice>(
		`${config.apiUrl}/${apiRoutes.practices}/store/${storeId}`,
		{
			method: 'GET',
		},
		'errors.practices.get',
	)

export const updatePracticeManagersApi = (
	practiceId: string,
	deletedUsers: string[],
	newStaff: string[],
) =>
	fetchJson<Practice>(
		`${config.apiUrl}/${apiRoutes.practices}/${practiceId}/managers`,
		{
			method: 'PUT',
			body: JSON.stringify({ deletedUsers, newStaff }),
		},
		'errors.practices.update',
	)

export const updateTimeoutsPracticeApi = (
	practiceId: Id,
	timeouts: TimeoutConfig,
) =>
	fetchJson<Practice>(
		`${config.apiUrl}/${apiRoutes.practices}/${practiceId}/timeouts`,
		{
			method: 'PATCH',
			body: JSON.stringify(timeouts),
		},
		'errors.practices.removeStore',
	)

export const getStoreReferralDoctorsApi = async (storeId: Id) =>
	await fetchJson<ReferralDoctor[]>(
		`${config.apiUrl}/${apiRoutes.stores}/${storeId}/referral-doctors`,
		{
			method: 'GET',
		},
		'errors.connection',
	)

export const createReferralDoctorsApi = async (body: CreateReferralDoctor) =>
	await fetchJson<ReferralDoctor>(
		`${config.apiUrl}/${apiRoutes.stores}/${body.store}/referral-doctors`,
		{
			method: 'POST',
			body: JSON.stringify(body),
		},
		'errors.connection',
	)

export const deleteStoreReferralDoctorsApi = async (
	storeId: Id,
	referralDoctorId: Id,
) =>
	await fetchJson<ReferralDoctor[]>(
		`${config.apiUrl}/${apiRoutes.stores}/${storeId}/referral-doctors/${referralDoctorId}`,
		{
			method: 'DELETE',
			body: JSON.stringify({}),
		},
		'errors.connection',
	)

export const updateStoreReferralDoctorsApi = (referralDoctor: ReferralDoctor) =>
	fetchJson<ReferralDoctor>(
		`${config.apiUrl}/${apiRoutes.stores}/${referralDoctor.store}/referral-doctors/${referralDoctor._id}`,
		{
			method: 'PUT',
			body: JSON.stringify(referralDoctor),
		},
		'errors.connection',
	)

export const getStoreReferralDoctorApi = async (
	storeId: Id,
	referralDoctorId: Id,
) =>
	await fetchJson<ReferralDoctor>(
		`${config.apiUrl}/${apiRoutes.stores}/${storeId}/referral-doctors/${referralDoctorId}`,
		{
			method: 'GET',
		},
		'errors.connection',
	)

export const upsertOrderReferraApi = (
	examId: Id,
	orderId: Id,
	body: ReferralDocumentBody,
) =>
	fetchJson<ExamApi>(
		`${config.apiUrl}/${apiRoutes.exams}/${examId}/order-referral-document/${orderId}`,
		{
			method: 'PUT',
			body: JSON.stringify(body),
		},
		'errors.connection',
	)

export const updateStoreStaffAPI = (storeId: Id, body: UpdateStoreStaffBody) =>
	fetchJson<Store>(
		`${config.apiUrl}/${apiRoutes.stores}/${storeId}/staff`,
		{
			method: 'PUT',
			body: JSON.stringify(body),
		},
		'errors.staff.update',
	)

export const updateStoreExternalIdsApi = (storeId: Id) =>
	fetchJson<Store>(
		`${config.apiUrl}/${routes.stores}/${storeId}/fhirsync`,
		{
			method: 'PATCH',
			body: JSON.stringify({}),
		},
		'errors.externalIds.update',
	)

export const findDif = async (
	parameters: DifProductFindRequest,
): Promise<TabDifForm | undefined> => {
	const encodedParameters = new URLSearchParams(parameters).toString()
	const url = `${config.apiUrlV2}/${routes.difProduct}/find?${encodedParameters}`
	const response = await fetch(url, {
		headers: getCommonHeaders({}),
	})
	const { ok, status } = response
	return !ok || status === 404 ? undefined : response.json()
}

export const sendReferralDocumentApi = (examId: Id, body: { send: boolean }) =>
	fetchJson<ExamApi>(
		`${config.apiUrl}/${apiRoutes.exams}/${examId}/send-referral-form-document`,
		{
			method: 'PUT',
			body: JSON.stringify(body),
		},
		'errors.connection',
	)

export const sendPrescriptionDocumentApi = (
	prescriptionId: Id,
	body: { send: boolean },
) =>
	fetchJson<Prescription>(
		`${config.apiUrl}/${apiRoutes.prescriptions}/${prescriptionId}/send`,
		{
			method: 'PUT',
			body: JSON.stringify(body),
		},
		'errors.connection',
	)

export const logAzureCallApi = (log: AzureLogger) =>
	fetch(`${config.apiUrl}/${apiRoutes.log}/azure`, {
		headers: getCommonHeaders({ addContentType: true }),
		method: 'POST',
		body: JSON.stringify(log),
	})

export const findRx = async (id: string) => {
	const res = await fetch(`${config.apiUrl}/${apiRoutes.prescriptions}/${id}`, {
		headers: {
			...getCommonHeaders({}),
			'Content-Type': 'application/pdf',
		},
	})

	return await getBlobForPdf(res)
}

export const generateChartApi = async (
	id: string,
	ignoreError: boolean = false,
) =>
	fetchJson<ExamApi>(
		`${config.apiUrlV2}/${apiRoutes.exams}/${id}/seal`,
		{
			method: 'PUT',
			body: JSON.stringify({}),
		},
		'errors.connection',
		false,
		undefined,
		false,
		ignoreError,
	)
export const saveLELogoApi = (practiceId: string, base64: string) =>
	fetchJson<Practice>(
		`${config.apiUrl}/${apiRoutes.practices}/${practiceId}/logo`,
		{
			method: 'PATCH',
			body: JSON.stringify({ base64 }),
		},
	)

export const deleteLELogoApi = (practiceId: string) =>
	fetchJson<Practice>(
		`${config.apiUrl}/${apiRoutes.practices}/${practiceId}/logo/remove`,
		{
			method: 'PATCH',
			body: JSON.stringify({}),
		},
	)

export const getLELogoApi = (practiceId: string) =>
	fetchJson<PracticeLogoAPI>(
		`${config.apiUrl}/${apiRoutes.practices}/${practiceId}/logo`,
		{},
	)

export const updateDif = (examId: Id, dif: DifForm) =>
	fetchJson<ExamApi>(
		`${config.apiUrlV2}/${apiRoutes.exams}/${examId}/dif`,
		{
			method: 'PATCH',
			body: JSON.stringify(dif),
		},
		'errors.connection',
	)

export const syncDifFromTab = (examId: Id) =>
	fetchJson<ExamApi>(
		`${config.apiUrlV3}/${apiRoutes.exams}/${examId}/sync-dif`,
		{
			method: 'PATCH',
			body: JSON.stringify({}),
		},
		'errors.connection',
	)

export const updateDoctorNotes = (examId: Id, notes: DoctorNotes) =>
	fetchJson<ExamApi>(
		`${config.apiUrlV2}/${apiRoutes.exams}/${examId}/doctorNotes`,
		{
			method: 'PATCH',
			body: JSON.stringify(notes),
		},
		'errors.connection',
	)

export const saveExamConditionApi = (
	examId: Id,
	payload: SaveExamConditionPayload,
) =>
	fetchJson<ExamApi>(
		`${config.apiUrlV2}/${apiRoutes.exams}/${examId}/conditions`,
		{
			method: 'POST',
			body: JSON.stringify(payload),
		},
		'errors.connection',
	)

export const getTabClinics = (siteNumber: string) =>
	fetchJson<TeloClinic[]>(
		`${config.apiUrl}/${apiRoutes.office}?siteNumber=${siteNumber}`,
		{},
		'errors.connection',
	)

export const exportFromExamApi = (
	fromExamId: Id,
	toExamId: Id,
	data: ExportToolData,
) =>
	fetchJson<ExamApi>(
		`${config.apiUrlV2}/${apiRoutes.exams}/exportFrom/${fromExamId}/to/${toExamId}`,
		{
			method: 'PATCH',
			body: JSON.stringify(data),
		},
		'errors.connection',
	)

export const getAllergiesAPI = (filter?: string) => {
	const $qs = filter && filter.length > 0 ? `?q=${filter}` : ''
	return fetchJson<Allergy[]>(
		`${config.apiUrl}/${apiRoutes.allergies}${$qs}`,
		{},
		'errors.connection',
	)
}

export const getSnomedCTAPI = (filter?: string) => {
	const $qs = filter && filter.length > 0 ? `?q=${filter}` : ''
	//we construct the route based on the api url this is needed because the env is not always the same
	const splittedConfigApiUrl = config.apiUrlV2.split('/')
	const snomedRoute =
		splittedConfigApiUrl.pop() === 'v2'
			? `${splittedConfigApiUrl[0]}//${splittedConfigApiUrl[2]}/api/system/v2`
			: 'v3'
	return fetchJson<SnomedCT[]>(
		`${snomedRoute}/${apiRoutes.snomedct}/paginated${$qs}`,
		{},
		'errors.connection',
	)
}

export const checkIfUserExists = (username: string) =>
	window
		.fetch(`${config.apiUrlV2}/${apiRoutes.users}/exists/${username}`, {
			method: 'GET',
		})
		.then(res => res.ok)

export const getCoreMediaContentAPI = () => {
	return fetchJson<ConnectCoreMediaContent>(
		`${config.apiUrlV2}/${apiRoutes.coremedia}`,
		{},
		'errors.connection',
	)
}

export const getCPTAPI = (filter?: string, organizationId?: string) => {
	const $qs = filter && filter.length > 0 ? `?q=${filter}` : ''
	return fetchJson<SystemCodeCPT[]>(
		`${unversionedApiUrl}/system/v2/cpt/paginated${$qs}`,
		{
			headers: {
				...getCommonHeaders({ addContentType: false }),
				...getSystemHeaders(organizationId),
			},
		},
		'errors.connection',
	)
}

export const getSymptomsAPI = (filter?: string) => {
	const $qs =
		filter && filter.length > 0 ? `?q=${filter}&enabled=true` : `?enabled=true`
	return fetchJson<SymptomsCode[]>(
		`${config.apiUrl}/${apiRoutes.symptoms}${$qs}`,
		{},
		'errors.connection',
	)
}

export const getICD10 = (filter?: string) => {
	const $qs = filter && filter.length > 0 ? `?q=${filter}` : ''
	return fetchJson<ICD10[]>(
		`${config.apiUrl}/${apiRoutes.icd10}/paginated${$qs}`,
		{},
		'errors.connection',
	)
}

export const getLabTestCpt = (filter?: string) => {
	const $qs = filter && filter.length > 0 ? `?q=${filter}` : ''
	return fetchJson<LabTestCpt[]>(
		`${config.apiUrl}/${apiRoutes.icd10}/paginated${$qs}`,
		{},
		'errors.connection',
	)
}

export const getLabTestLoinc = (filter?: string) => {
	const $qs = filter && filter.length > 0 ? `?q=${filter}` : ''
	return fetchJson<LabTestLoinc[]>(
		`${config.apiUrl}/${apiRoutes.icd10}/paginated${$qs}`,
		{},
		'errors.connection',
	)
}

export const getSpecialTestCpt = (filter?: string) => {
	const $qs = filter && filter.length > 0 ? `?q=${filter}` : ''
	return fetchJson<SpecialTestCpt[]>(
		`${config.apiUrl}/${apiRoutes.icd10}/paginated${$qs}`,
		{},
		'errors.connection',
	)
}

export const removeProcedureApi = ({
	examId,
	procedureCode,
}: {
	examId: string
	procedureCode: string
}) => {
	return fetchJson<ExamApi | StrippedExam>(
		`${config.apiUrlV2}/${apiRoutes.exams}/${examId}/procedures/${procedureCode}/delete`,
		{
			method: 'PATCH',
			body: JSON.stringify({}),
		},
		'errors.connection',
	)
}

export const uploadExamProcedureImageApi = ({
	examId,
	procedureCode,
	documentType,
	fileContent,
	fileName,
	uploadedBy,
}: {
	examId: string
	procedureCode: string
	documentType: string
	fileContent: string
	fileName: string
	uploadedBy: string
}) => {
	return fetchJson<ExamApi | StrippedExam>(
		`${config.apiUrlV2}/${apiRoutes.exams}/${examId}/procedures/${procedureCode}/image`,
		{
			method: 'POST',
			body: JSON.stringify({
				documentType,
				fileContent,
				fileName,
				uploadedBy,
			}),
		},
		'errors.connection',
	)
}

export const deleteExamProcedureImageApi = (
	examId: string,
	procedureCode: string,
	documentRef: string,
) => {
	return fetchJson<ExamApi | StrippedExam>(
		`${config.apiUrlV2}/${apiRoutes.exams}/${examId}/procedures/${procedureCode}/image/${documentRef}/delete`,
		{
			method: 'DELETE',
		},
		'errors.connection',
	)
}

export const getModifiersAPI = (filter?: string) => {
	const $qs = filter && filter.length > 0 ? `?q=${filter}` : ''
	return fetchJson<Modifier[]>(
		`${config.apiUrl}/${apiRoutes.modifiers}${$qs}`,
		{
			headers: {
				...getCommonHeaders({ addContentType: false }),
				...getSystemHeaders(),
			},
		},
		'errors.connection',
	)
}

type RawSummaryContactLenses = Omit<
	SummaryContactLenses,
	'trialType' | 'modality'
> & {
	trialType: string
	modality: string
}
type RawSummary = Omit<Summary, 'contactLenses'> & {
	contactLenses: SummaryData<RawSummaryContactLenses[]>[]
}
export const getSummary = async (patientId: string): Promise<Summary> => {
	const url = `${config.apiUrlV2}/${apiRoutes.summary}/technician/patient/${patientId}`
	return fetch(url, {
		headers: getCommonHeaders({}),
	}).then(res => res.json())
}

export const removeScreeningProcedureApi = ({
	examId,
	screeningProcedureId,
}: {
	examId: string
	screeningProcedureId: string
}) => {
	return fetchJson<ExamApi | StrippedExam>(
		`${config.apiUrlV2}/${apiRoutes.exams}/${examId}/screeningProcedures/${screeningProcedureId}/delete`,
		{
			method: 'PATCH',
			body: JSON.stringify({}),
		},
		'errors.connection',
	)
}

export const uploadExamScreeningProcedureAttachmentApi = ({
	examId,
	screeningProcedureId,
	fileContent,
	fileName,
	fileSize,
	documentType,
	uploadedBy,
}: {
	examId: string
	screeningProcedureId: string
	fileContent: string
	fileName: string
	fileSize: number
	documentType: string
	uploadedBy: string
}) => {
	return fetchJson<ExamDocumentWithFileSize>(
		`${config.apiUrlV2}/${apiRoutes.exams}/${examId}/screeningProcedures/${screeningProcedureId}/attachment`,
		{
			method: 'POST',
			body: JSON.stringify({
				fileContent,
				fileName,
				fileSize: fileSize?.toString(),
				documentType,
				uploadedBy,
			}),
		},
		'errors.connection',
	)
}

export const uploadExamScreeningProcedureImageApi = ({
	examId,
	screeningProcedureId,
	fileContent,
	fileName,
	documentType,
	uploadedBy,
}: {
	examId: string
	screeningProcedureId: string
	fileContent: string
	fileName: string
	documentType: string
	uploadedBy: string
}) => {
	return fetchJson<ExamDocument>(
		`${config.apiUrlV2}/${apiRoutes.exams}/${examId}/screeningProcedures/${screeningProcedureId}/image`,
		{
			method: 'POST',
			body: JSON.stringify({
				fileContent,
				fileName,
				documentType,
				uploadedBy,
			}),
		},
		'errors.connection',
	)
}

export const deleteExamScreeningProcedureImageApi = (
	examId: string,
	screeningProcedureId: string,
	documentRef: string,
) => {
	return fetchJson<ExamDocument>(
		`${config.apiUrlV2}/${apiRoutes.exams}/${examId}/screeningProcedures/${screeningProcedureId}/image/${documentRef}/delete`,
		{
			method: 'DELETE',
		},
		'errors.connection',
	)
}

export const deleteExamScreeningProcedureAttachmentApi = (
	examId: string,
	screeningProcedureId: string,
	documentRef: string,
) => {
	return fetchJson<ExamDocument>(
		`${config.apiUrlV2}/${apiRoutes.exams}/${examId}/screeningProcedures/${screeningProcedureId}/attachment/${documentRef}/delete`,
		{
			method: 'DELETE',
		},
		'errors.connection',
	)
}

export const uploadOrderAttachmentApi = ({
	examId,
	orderId,
	fileContent,
	fileName,
	documentType,
	uploadedBy,
}: {
	examId: string
	orderId: string
	fileContent: string
	fileName: string
	documentType: string
	uploadedBy: string
}) => {
	return fetchJson<ExamApi | StrippedExam>(
		`${config.apiUrlV2}/${apiRoutes.exams}/${examId}/orders/${orderId}/attachment`,
		{
			method: 'POST',
			body: JSON.stringify({
				fileContent,
				fileName,
				documentType,
				uploadedBy,
			}),
		},
		'errors.connection',
	)
}

export const saveOrderResultApi = (
	examId: string,
	orderId: string,
	payload: OrderResult,
) => {
	return fetchJson<ExamApi | StrippedExam>(
		`${config.apiUrlV2}/${apiRoutes.exams}/${examId}/orders/${orderId}/results`,
		{
			method: 'POST',
			body: JSON.stringify({ ...payload }),
		},
		'errors.connection',
	)
}

export const upsertOrderResultApi = (
	examId: string,
	orderId: string,
	payload: OrderResult,
) => {
	return fetchJson<ExamApi | StrippedExam>(
		`${config.apiUrlV2}/${apiRoutes.exams}/${examId}/orders/${orderId}/results/${payload.id}`,
		{
			method: 'PUT',
			body: JSON.stringify({ ...payload }),
		},
		'errors.connection',
	)
}

export const deleteOrderResultApi = (
	examId: string,
	orderId: string,
	resultId: string,
) => {
	return fetchJson<ExamApi | StrippedExam>(
		`${config.apiUrlV2}/${apiRoutes.exams}/${examId}/orders/${orderId}/results/${resultId}`,
		{
			method: 'DELETE',
		},
		'errors.connection',
	)
}

export const uploadOrderResultAttachmentApi = async ({
	examId,
	orderId,
	attachment,
}: {
	examId: string
	orderId: string
	attachment: {
		fileContent: string
		fileName: string
		documentType: string
		uploadedBy: string
		fileSize: number
	}
}) => {
	return Promise.resolve(
		fetchJson<ExamDocument>(
			`${config.apiUrlV2}/${apiRoutes.exams}/${examId}/orders/${orderId}/attachment`,
			{
				method: 'POST',
				body: JSON.stringify(attachment),
			},
			'errors.connection',
		),
	).then(res => {
		return res
	})
}

export const uploadOrderResultAttachmentsApi = async ({
	examId,
	orderId,
	attachments,
}: {
	examId: string
	orderId: string
	attachments: {
		fileContent: string
		fileName: string
		documentType: string
		uploadedBy: string
		fileSize: number
	}[]
}) => {
	return Promise.resolve(
		fetchJson<ExamOrderResultDocument[]>(
			`${config.apiUrlV2}/${apiRoutes.exams}/${examId}/orders/${orderId}/attachments`,
			{
				method: 'POST',
				body: JSON.stringify(attachments),
			},
			'errors.connection',
		),
	).then(res => {
		return res
	})
}

export const downloadOrderResultAttachmentApi = (
	examId: string,
	orderId: string,
	result: OrderResult,
	attachment: ExamDocument,
) => {
	const { id } = result

	if (!attachment) {
		throw Error('Cannot download attachment because is not present')
	}

	return fetchBlob(
		`${config.apiUrlV2}/${apiRoutes.exams}/${examId}/orders/${orderId}/results/${id}/attachment/${attachment.ref}`,
		{
			method: 'GET',
		},
		'errors.connection',
	)
}

export const deleteOrderAttachmentsApi = (
	examId: string,
	orderId: string,
	result: OrderResult,
	attachments: string[],
) => {
	const { id } = result

	if (!attachments) {
		throw Error('Cannot delete attachments because is not present')
	}

	return fetchJson<ExamApi | StrippedExam>(
		`${config.apiUrlV2}/${
			apiRoutes.exams
		}/${examId}/orders/${orderId}/results/${id}/attachments/${attachments.join()}`,
		{
			method: 'DELETE',
		},
		'errors.connection',
	)
}

export const uploadOrderResultImageApi = ({
	examId,
	orderId,
	resultId,
	fileContent,
	fileName,
	documentType,
	uploadedBy,
}: {
	examId: string
	orderId: string
	resultId: string
	fileContent: string
	fileName: string
	documentType: string
	uploadedBy: string
}) => {
	return Promise.resolve(
		fetchJson<ExamDocument>(
			`${config.apiUrlV2}/${apiRoutes.exams}/${examId}/orders/${orderId}/results/${resultId}/image`,
			{
				method: 'POST',
				body: JSON.stringify({
					fileContent,
					fileName,
					documentType,
					uploadedBy,
				}),
			},
			'errors.connection',
		),
	).then(value => (!!value ? value : undefined))
}

export const deleteOrderResultImageApi = ({
	examId,
	orderId,
	resultId,
	ref,
}: {
	examId: string
	orderId: string
	resultId: string
	ref: string
}) => {
	return fetchJson<ExamApi | StrippedExam>(
		`${config.apiUrlV2}/${apiRoutes.exams}/${examId}/orders/${orderId}/results/${resultId}/image/${ref}`,
		{
			method: 'DELETE',
		},
		'errors.connection',
	)
}

export const deleteExamOtherTestImageApi = (
	examId: string,
	otherTestCode: string,
	documentRef: string,
) => {
	return fetchJson<boolean | undefined>(
		`${config.apiUrlV2}/${apiRoutes.exams}/${examId}/other-test/${otherTestCode}/image/${documentRef}/delete`,
		{
			method: 'DELETE',
		},
		'errors.connection',
	)
}

export const uploadOtherExamImageApi = ({
	examId,
	otherTestCode,
	fileContent,
	fileName,
	documentType,
	uploadedBy,
}: {
	examId: string
	otherTestCode: string
	fileContent: string
	fileName: string
	documentType: string
	uploadedBy: string
}) => {
	return Promise.resolve(
		fetchJson<ExamDocumentWithFileSize | undefined>(
			`${config.apiUrlV2}/${apiRoutes.exams}/${examId}/other-test/${otherTestCode}/image`,
			{
				method: 'POST',
				body: JSON.stringify({
					fileContent,
					fileName,
					documentType,
					uploadedBy,
				}),
			},
			'errors.connection',
		),
	).then(value => (!!value ? value : undefined))
}

export const uploadOtherTestAttachmentApi = ({
	examId,
	otherTestCode,
	fileContent,
	fileName,
	documentType,
	uploadedBy,
}: {
	examId: string
	otherTestCode: string
	fileContent: string
	fileName: string
	documentType: string
	uploadedBy: string
}) => {
	return fetchJson<ExamDocumentWithFileSize | undefined>(
		`${config.apiUrlV2}/${apiRoutes.exams}/${examId}/other-test/${otherTestCode}/attachment`,
		{
			method: 'POST',
			body: JSON.stringify({
				fileContent,
				fileName,
				documentType,
				uploadedBy,
			}),
		},
		'errors.connection',
	)
}

export const deleteExamOtherTestAttachmentApi = (
	examId: string,
	otherTestCode: string,
	documentRef: string,
) => {
	return fetchJson<boolean | undefined>(
		`${config.apiUrlV2}/${apiRoutes.exams}/${examId}/other-test/${otherTestCode}/attachment/${documentRef}/delete`,
		{
			method: 'DELETE',
		},
		'errors.connection',
	)
}

export const retrieveAxialLength = async ({
	gender: genderParam,
	ethnicity: ethnicityParam,
	values,
}: {
	values: AgeAndValue[]
	gender?: string
	ethnicity?: string
}) => {
	const gender = ['female', 'male'].includes(genderParam ?? '')
		? genderParam?.toLocaleLowerCase()
		: 'male'
	const ethnicity = !!ethnicityParam ? ethnicityParam : 'other'
	return fetchJson<{ data: CalculatedAxialLengthData }>(
		`${config.apiUrlV2}/${apiRoutes.myopia}/calculate-axial-length`,
		{
			method: 'POST',
			body: JSON.stringify({ ethnicity, gender, values }),
		},
		'errors.connection',
	)
}

export const retrieveSphereEquivalent = async (values: AgeAndValue[]) => {
	return fetchJson<{ data: CalculatedSphereEqData }>(
		`${config.apiUrlV2}/${apiRoutes.myopia}/calculate-sphere-equivalent`,
		{
			method: 'POST',
			body: JSON.stringify({ values }),
		},
		'errors.connection',
	)
}

export const getOtherTestImages = async (
	container: CONTAINER_NAME,
	imageRef: string,
) => {
	const response = await window.fetch(
		`${config.apiUrl}/images/${container}/${imageRef}`,
		{
			headers: getCommonHeaders({}),
		},
	)

	const blob = await response.blob()

	return URL.createObjectURL(blob)
}

export const getTroubleShootingDataAPI = () => {
	return fetchJson<TroubleShootingData>(
		`${config.apiUrlV2}/${apiRoutes.troubleShooting}`,
		{},
		'errors.connection',
	)
}

export const getHistoricalExamsDataByType = async (
	internalPatientId: string,
	examType: InstrumentType | 'BI',
) => {
	return fetchJson<{ data: WithExamId<GetInstrumentDataResponseApi>[] }>(
		`${config.apiUrlV2}/${apiRoutes.exams}/historical-data/${internalPatientId}/${examType}`,
		{
			method: 'GET',
		},
		'errors.connection',
	)
}

export const hasMyopiaAlert = async ({
	age,
	data,
	lifeEnvironment,
	familyHistory,
	timeSpentNearVision,
	timeSpentOutside,
}: {
	age: number | null
	data: {
		R: {
			sphere: string | undefined | null
			cylinder: string | undefined | null
		}
		L: {
			sphere: string | undefined | null
			cylinder: string | undefined | null
		}
	}
	lifeEnvironment?: string
	familyHistory?: string
	timeSpentNearVision?: string
	timeSpentOutside?: string
}) => {
	return fetchJson<HasMyopiaAlertResponse>(
		`${config.apiUrlV2}/${
			apiRoutes.alerts
		}/has-myopia-alert?${new URLSearchParams({
			age: age ? age.toString() : '',
			data: JSON.stringify(data),
			lifeEnvironment: lifeEnvironment || '',
			familyHistory: familyHistory || '',
			timeSpentNearVision: timeSpentNearVision || '',
			timeSpentOutside: timeSpentOutside || '',
		})}`,
		{
			method: 'GET',
		},
		'errors.connection',
	)
}

export const getHasSensitivityAlert = (sensitivity: number) => {
	return fetchJson<HasSensitivityAlertResponse>(
		`${config.apiUrlV2}/${apiRoutes.alerts}/has-sensitivity-alert?sensitivity=${sensitivity}`,
		{
			method: 'GET',
		},
		'errors.connection',
	)
}

export const getAccommodationAlert = async ({ age }: Age) => {
	return fetchJson<AccommodationAlert>(
		`${config.apiUrlV2}/${
			apiRoutes.accommodation
		}/get-accommodation-alert?${new URLSearchParams({ age: age.toString() })}`,
		{
			method: 'GET',
		},
		'errors.connection',
	)
}

export const calculateAccommodation = async ({
	age,
	equivalentSphereAmplitudeRight,
	equivalentSphereAmplitudeLeft,
}: AccommodationParameters) => {
	return fetchJson<AccommodationData>(
		`${config.apiUrlV2}/${apiRoutes.accommodation}/calculate-accommodation`,
		{
			method: 'POST',
			body: JSON.stringify({
				age,
				sphereEquivalentRight: equivalentSphereAmplitudeRight,
				sphereEquivalentLeft: equivalentSphereAmplitudeLeft,
			}),
		},
		'errors.connection',
	)
}

export const generateMyopiaReport = ({
	examId,
	graphs,
	refraction,
	controlOptionsRaccomandations,
	lifeStyleRaccomandations,
	save,
}: MyopiaCare & { examId: string; save: boolean }) =>
	fetch(`${config.apiUrlV2}/${apiRoutes.myopia}/${examId}/report`, {
		method: 'POST',
		headers: getCommonHeaders({ addContentType: true }),
		body: JSON.stringify({
			graphs,
			refraction,
			controlOptionsRaccomandations,
			lifeStyleRaccomandations,
			save,
		}),
	}).then(res => res.text())

export const deleteAppointmentApi = (id: Id) =>
	fetchJson<void>(
		`${config.apiUrl}/${apiRoutes.appointments}/${id}`,
		{
			method: 'DELETE',
		},
		'errors.connection',
	)

export const createNewPatientApi = (patientData: FirstDataModel) =>
	fetchJson<PatientApi>(
		`${config.apiUrl}/${apiRoutes.patients}`,
		{
			method: 'POST',
			body: JSON.stringify(patientData),
		},
		'errors.connection',
	)

export const createNewAppointmentApi = (
	appointmentData: CreateTabAppointmentPayload,
) =>
	fetchJson<TeloAppointment>(
		`${config.apiUrl}/${apiRoutes.appointments}`,
		{
			method: 'POST',
			body: JSON.stringify(appointmentData),
		},
		'errors.connection',
	)

export const updateAppointmentApi = (
	appointmentId: string,
	appointmentData: UpdateAppointmentPayload,
) =>
	fetchJson<TeloAppointment>(
		`${config.apiUrl}/${apiRoutes.appointments}/${appointmentId}`,
		{
			method: 'PUT',
			body: JSON.stringify(appointmentData),
		},
		'errors.connection',
	)
export const getAvailableSlotsApi = (
	appType: string,
	appDate: string,
	storeId: string,
) => {
	const paramsQuery = {
		appType,
		appDate,
		preferredFlag: 'FALSE',
	}

	return fetchJson<TimeSlotData[]>(
		`${config.apiUrl}/${
			apiRoutes.appointments
		}/${storeId}/slots${buildQueryString(paramsQuery)}`,
		undefined,
		'errors.connection',
	)
}

export const lockSlotApi = (scheduleId: string): Promise<LockedSlot> =>
	fetchJson<any>(
		`${config.apiUrl}/${apiRoutes.appointments}/lock`,
		{
			method: 'PUT',
			body: JSON.stringify({
				// storeId,
				scheduleId,
			}),
		},
		'errors.connection',
	)

export const freeLockedSlotApi = (id: string): Promise<LockedSlot> =>
	fetchJson<any>(
		`${config.apiUrl}/${apiRoutes.appointments}/lock/${id}`,
		{
			method: 'DELETE',
			// fix for safari that breaks if body is empty
			body: JSON.stringify({
				sessionId: '',
			}),
		},
		'errors.connection',
	)

export const getToTFromPatient = (patientId: string) => {
	return fetchJson<InstrumentDataResponseApi[]>(
		`${config.apiUrlV2}/${apiRoutes.trendsovertime}/patient/${patientId}`,
		{},
		'errors.connection',
	)
}

export const refreshPatientDataApi = (patientId: string, examId: string) =>
	fetchJson<ExamApi>(
		`${config.apiUrl}/${apiRoutes.patients}/${patientId}`,
		{
			method: 'PUT',
			body: JSON.stringify({ examId }),
		},
		'errors.connection',
	)

export const updateMedicalReportApi = async (
	examId: string,
	mr: ExamMedicalReport,
) => {
	let updatedMedicalReport

	if (mr.attachment) {
		const updatedAttachmentRef = await convertToBase64(
			await getBlobFromAttachmentRef(mr.attachment?.ref),
		)

		updatedMedicalReport = {
			...mr,
			attachment: {
				...mr.attachment,
				ref: updatedAttachmentRef as string,
			},
		}
	} else {
		updatedMedicalReport = { ...mr }
	}

	return upsertMedicalReportApi(examId, mr.id, updatedMedicalReport)
}

export const refreshDocumentsApi = async (exam: Exam) => {
	const promises: Promise<ExamApi | string | Prescription>[] = []

	if (exam.eyeHealth?.document) {
		promises.push(updateEyeHealthDocApi(exam._id))
	}

	if (exam.medicalReports) {
		exam.medicalReports.forEach(mr => {
			promises.push(updateMedicalReportApi(exam._id, mr))
		})
	}

	if (exam.orders) {
		exam.orders.forEach(order => {
			promises.push(upsertOrderReferraApi(exam._id, order.id, {}))
		})
	}

	await Promise.all(promises)

	const updateExamResult = await updateExamApi(exam._id, {
		canRefreshDocuments: false,
	})

	return {
		exam: updateExamResult,
	}
}

export const getContactLensesReport = async (examId: string, index: number) =>
	fetch(
		`${config.apiUrl}/${apiRoutes.exams}/${examId}/contactLenses/${index}/report`,
		{
			headers: {
				...getCommonHeaders({}),
				accept: 'application/pdf',
			},
		},
	).then(res => res.blob())

export const getVaScalesAPI = () =>
	fetchJson<VAScaleConversion[]>(
		`${config.apiUrl}/${apiRoutes.vaScales}`,
		{},
		'errors.connection',
	)

export const getImplantableDevice = (udi: string) => {
	return fetchJson<ImplantableDevice | ImplantableDeviceError>(
		`${config.apiUrl}/implantabledevice?udi=${encodeURIComponent(udi)}`,
		{},
		'errors.connection',
	)
}

export const getExamUpdate = (examId: string) => {
	return fetchJson<BaseExamUpdate[]>(
		`${config.apiUrl}/${apiRoutes.exams}/${examId}/updates`,
		{},
		'errors.connection',
	)
}

export const getExamUpdateCompare = (
	examId: string,
	versions: string[],
	valuesType: string,
) => {
	return fetchJson<ExamUpdateCompare>(
		`${config.apiUrl}/${
			apiRoutes.exams
		}/${examId}/updates/compare?versionIds=${versions.join(
			',',
		)}&valuesType=${valuesType}`,
		{},
		'errors.connection',
	)
}

export const getRuntimeChartApi = async (examId: Id) => {
	const response = await window.fetch(
		`${config.apiUrlV2}/${apiRoutes.exams}/${examId}/chart`,
		{
			headers: {
				...getCommonHeaders({}),
			},
		},
	)

	return await getBlobForPdf(response)
}

export const getAllReports = () =>
	fetchJson<ReportResponse>(
		`${config.apiUrlV2}/${apiRoutes.reports}`,
		{},
		'errors.connection',
	)

export const getFilteredReports = (
	isLEManager?: boolean,
	userName?: string,
	name?: string,
	type?: string,
	storeIds?: string[],
	dateFrom?: string,
	dateTo?: string,
	page = 0,
	limit?: number,
) => {
	const query = {
		isLEManager,
		userName,
		name,
		type,
		storeIds: storeIds?.join(','),
		dateFrom,
		dateTo,
		page,
		limit,
	}
	return fetchJson<ReportResponse>(
		`${config.apiUrlV2}/${apiRoutes.reports}/paginated?${qs.stringify(query)}`,
		{},
		'errors.connection',
	)
}

export const postReport = async (
	name: string,
	dateFrom: Date,
	dateTo: Date,
	storeIds: string[],
	reportType: 'examVolume' | 'examTiming' | 'full' | 'optometrist',
	fields: string[],
	categorizedFields: {
		generalInformation: string[]
		appointment: string[]
		phase: string[]
		operator: string[]
	},
) => {
	return fetchJson<Report>(
		`${config.apiUrlV2}/report`,
		{
			method: 'POST',
			body: JSON.stringify({
				name,
				dateFrom,
				dateTo,
				storeIds,
				type: reportType,
				fields,
				categorizedFields,
			}),
		},
		'errors.connection',
	)
}

export const getReportById = async (id: string, name: string) => {
	const fetchOptions = {
		method: 'GET',
		headers: {
			...getCommonHeaders({}),
			Accept:
				'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, */*',
			'Content-Type':
				'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		},
	}

	const request = new Request(`${config.apiUrlV2}/reports/${id}`, fetchOptions)
	const response = await window.fetch(request)
	const fileURL = window.URL.createObjectURL(await response.blob())
	const a = document.createElement('a')
	a.href = fileURL
	a.download = name
	document.body.appendChild(a)
	a.click()
	a.remove()
}

export const removeReportApi = (id: string) => {
	return fetchJson<Report>(
		`${config.apiUrlV2}/reports/${id}`,
		{
			method: 'DELETE',
			body: JSON.stringify({}),
		},
		'errors.connection',
	)
}

export const getMedicalReportTemplatesApi = async () =>
	await fetchJson<MedicalReportTemplate[]>(
		`${config.apiUrl}/medical-report-templates`,
		{
			method: 'GET',
		},
		'errors.connection',
	)

export const postMedicalReportTemplatesApi = async (
	body: CreateMedicalReportTemplate,
) =>
	await fetchJson<MedicalReportTemplate>(
		`${config.apiUrl}/medical-report-templates`,
		{
			method: 'POST',
			body: JSON.stringify(body),
		},
		'errors.connection',
	)

export const putMedicalReportTemplatesApi = async (
	body: UpdateMedicalReportTemplate,
) =>
	await fetchJson<MedicalReportTemplate>(
		`${config.apiUrl}/medical-report-templates/${body._id}`,
		{
			method: 'PUT',
			body: JSON.stringify(body),
		},
		'errors.connection',
	)

export const deleteMedicalReportTemplatesApi = async (templateId: Id) =>
	await fetchJson(
		`${config.apiUrl}/medical-report-templates/${templateId}`,
		{
			method: 'DELETE',
			body: JSON.stringify({}),
		},
		'errors.connection',
	)

export const upsertMedicalReportApi = async (
	examId: Id,
	medicalReportId: Id,
	body: Partial<ExamMedicalReport>,
) =>
	await fetchJson<ExamApi>(
		`${config.apiUrlV2}/${apiRoutes.exams}/${examId}/medical-reports/${medicalReportId}`,
		{
			method: 'PATCH',
			body: JSON.stringify(body),
		},
		'errors.connection',
	)

export const getVisionTherapy = async (
	patientId: string,
	examId: string,
): Promise<VisionTherapyApi> => {
	const url = `${config.apiUrlV2}/${apiRoutes.visionTherapy}/technician/patient/${patientId}/exam/${examId}`
	return fetch(url, {
		headers: getCommonHeaders({}),
	}).then(res => res.json())
}

export const getPracticesAlertCategoriesApi = () => {
	return fetchJson<AlertCategory[]>(
		`${config.apiUrl}/${apiRoutes.practices}/alertcategories`,
		{
			method: 'GET',
		},
		'errors.practices.get',
	)
}

export const getNamesFromUsername = (usernames: string[]) => {
	return fetchJson<{ username: string; name: string; surname: string }[]>(
		`${config.apiUrl}/${routes.users}/names?usernames=${usernames.join(',')}`,
		{
			method: 'GET',
		},
		'errors.namesFromUsernames.get',
	)
}

export const getEmergencyAccesses = (examId: string) => {
	return fetchJson<ExamEmergencyAccess[]>(
		`${config.apiUrl}/${apiRoutes.exams}/${examId}/emergency-accesses`,
		{},
		'errors.connection',
	)
}

export const enablePracticeAlertCategoryApi = (
	alertId: string,
	value: boolean,
	practiceId: string,
) => {
	return fetchJson<Practice>(
		`${config.apiUrl}/${apiRoutes.practices}/${practiceId}/alertcategory/${alertId}`,
		{
			method: 'PATCH',
			body: JSON.stringify({
				path: 'enabled',
				value,
			}),
		},
	)
}

export const enablePracticeTaskApi = (
	taskId: string,
	value: boolean,
	practiceId: string,
) => {
	return fetchJson<Practice>(
		`${config.apiUrl}/${apiRoutes.practices}/${practiceId}/task/${taskId}`,
		{
			method: 'PATCH',
			body: JSON.stringify({
				path: 'enabled',
				value,
			}),
		},
	)
}

export const enablePracticeCDSApi = (
	ruleId: CDS_RULE,
	value: boolean,
	practiceId: string,
) => {
	return fetchJson<Practice>(
		`${config.apiUrl}/${apiRoutes.practices}/${practiceId}/cds/${ruleId}`,
		{
			method: 'PATCH',
			body: JSON.stringify({
				path: 'enabled',
				value,
			}),
		},
	)
}

export const getTasksApi = async () =>
	await fetchJson<Task[]>(
		`${config.apiUrlV2}/tasks`,
		{
			method: 'GET',
		},
		'errors.connection',
	)

export const saveTasksApi = async (body: Task, id?: string) => {
	if (id !== undefined) {
		return await fetchJson<Task>(
			`${config.apiUrlV2}/tasks/${id}`,
			{
				method: 'PUT',
				body: JSON.stringify(body),
			},
			'errors.connection',
		)
	}

	return await fetchJson<Task>(
		`${config.apiUrlV2}/task`,
		{
			method: 'POST',
			body: JSON.stringify(body),
		},
		'errors.connection',
	)
}

export const deleteTasksApi = async (id: string) =>
	await fetchJson<Task>(
		`${config.apiUrlV2}/tasks/${id}`,
		{
			method: 'DELETE',
		},
		'errors.connection',
	)

export const getPatientAlerts = async (
	organizationId: string,
	patientId: string,
) =>
	await fetchJson<UprisePatientAlert[]>(
		`${config.apiUrlV3}/gemini/${organizationId}/patients/${patientId}/alerts`,
		{
			method: 'GET',
		},
		'errors.connection',
	)

export const createUpriseTaskApi = async (task: UpriseTask) =>
	await fetchJson(
		`${config.apiUrlV3}/gemini/task`,
		{
			method: 'POST',
			body: JSON.stringify(task),
		},
		'errors.connection',
	)

export const getExamCodesApi = async (storeId: string, examId: string) =>
	await fetchJson<any>(
		`${config.apiUrlV2}/coding/store/${storeId}/exam/${examId}`,
		{
			method: 'GET',
		},
		'errors.connection',
	)

export const sendCodingToGemini = async (examId: string, payload: Coding) =>
	await fetchJson<UprisePatientAlert[]>(
		`${config.apiUrlV3}/gemini/eyeexam/${examId}`,
		{
			method: 'POST',
			body: JSON.stringify(payload),
		},
		'errors.connection',
	)

export const getPolicies = async (
	organizationId: string,
	patientId: string,
): Promise<Policy[]> =>
	await fetchJson<any>(
		`${config.apiUrlV3}/gemini/${organizationId}/patients/${patientId}/policies`,
		{
			method: 'GET',
		},
		'errors.connection',
	)

export const upsertPracticeSurgeonsApi = (
	practiceId: string,
	surgeons: Surgeon[],
) => {
	return fetchJson<Practice>(
		`${config.apiUrl}/${apiRoutes.practices}/${practiceId}/surgeons`,
		{
			method: 'PATCH',
			body: JSON.stringify({ surgeons }),
		},
		'errors.connection',
	)
}

export const getActivePatientExport = (storeId: string) =>
	fetchJson<ExportState[]>(
		`${config.apiUrlV3}/export/store/${storeId}/active`,
		{
			method: 'GET',
		},
		'errors.connection',
	)

export const createPatientExport = (selectedStoreId: Id, patientId: Id) =>
	fetchJson<ExamApi>(
		`${config.apiUrlV3}/export`,
		{
			method: 'POST',
			body: JSON.stringify({
				storeId: `${selectedStoreId}`,
				patientIds: [`${patientId}`],
			}),
		},
		'errors.connection',
	)

export const getPatientExportDetails = (exportId: Id) =>
	fetchJson<{ password: string; downloadUrl: string }>(
		`${config.apiUrlV3}/export/${exportId}/download-details`,
		{
			method: 'POST',
			body: JSON.stringify({}),
		},
		'errors.connection',
	)

export const upsertSurgeryDocumentApi = (examId: Id, surgeryId: Id) =>
	fetchJson<ExamApi>(
		`${config.apiUrl}/${apiRoutes.exams}/${examId}/surgeries/${surgeryId}/document`,
		{
			method: 'PUT',
			body: JSON.stringify({}),
		},
		'errors.connection',
	)
